import type React from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@infinitaslearning/pixel-design-system";
import type { HelpNavMenuItem } from "@components/help-navigation/use-help-navigation";

type HelpNavigationMenuItemProps = {
  item: HelpNavMenuItem;
  handleClose: () => void;
  isInclass?: boolean;
};

const HelpNavigationMenuItem: React.FC<HelpNavigationMenuItemProps> = ({
  item,
  handleClose,
  isInclass,
}) => {
  const { name, href, handleClick, icon, isHiddenInclass, isOpenNewTab } = item;

  return (
    <MenuItem
      key={`menu-item-${name}`}
      onClick={() => {
        handleClick?.();
        handleClose();
      }}
      sx={{
        display: isInclass && isHiddenInclass ? "none" : "flex",
      }}
      component={"a"}
      href={href ? href : undefined}
      target={isOpenNewTab ? "_blank" : undefined}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{name}</ListItemText>
    </MenuItem>
  );
};

export default HelpNavigationMenuItem;
