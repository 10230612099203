import { isUserAlphaOrBeta } from "@components/user-context/helper";
import {
  FEATURE_TOGGLES_COOKIE,
  FEATURE_TOGGLES_COOKIE_MAX_AGE,
  UserContext,
} from "@components/user-context/user-context";
import {
  Box,
  Link,
  List,
  ListItem,
  Switch,
  Tooltip,
  Typography,
} from "@infinitaslearning/pixel-design-system";
import ChevronDownIcon from "@infinitaslearning/pixel-icons/ChevronDown";
import ChevronUpIcon from "@infinitaslearning/pixel-icons/ChevronUp";
import { ellipsis, multiLineEllipsis } from "@styles/helpers/util";
import { useTranslation } from "next-i18next";
import { useContext, useState } from "react";
import { useCookies } from "react-cookie";

export const FeatureFlagSelector = () => {
  const userContext = useContext(UserContext);
  const { t: configTranslation } = useTranslation("common", { keyPrefix: "config-toggles" });
  const [cookies, setCookie] = useCookies([FEATURE_TOGGLES_COOKIE]);
  const [expandedDescriptions, setExpandedDescriptions] = useState<string[]>([]);

  // Only show the feature flag selector if the user is an alpha or beta user
  const features = userContext && isUserAlphaOrBeta(userContext) ? userContext?.features ?? [] : [];
  const toggles = features.filter((featureFlag) => featureFlag.isToggleable);

  if (toggles.length === 0) {
    return null;
  }

  const toggleFeatureFlag = (key: string) => {
    const newValue = !toggles.find((x) => x.key === key)?.value;
    const featureFlagCookie = cookies[FEATURE_TOGGLES_COOKIE] ?? [];

    const updatedFeatureFlagCookie = featureFlagCookie.filter(
      (keyValue: { key: string; value: string }) => {
        return keyValue.key !== key && toggles.find((x) => x.key === keyValue.key);
      },
    );
    updatedFeatureFlagCookie.push({ key: key, value: newValue });
    setCookie(FEATURE_TOGGLES_COOKIE, updatedFeatureFlagCookie, {
      maxAge: FEATURE_TOGGLES_COOKIE_MAX_AGE,
      path: "/",
      sameSite: "strict",
    });

    if (userContext) {
      const newFeatures = userContext.features?.map((featureFlag) => {
        return featureFlag.key === key
          ? { key: key, value: newValue, isToggleable: true }
          : featureFlag;
      });

      userContext.setFeatures(newFeatures);
    }
  };

  return (
    <List sx={{ mt: 2, mb: 2, overflow: "auto" }}>
      {toggles.map((toggle) => (
        <ListItem
          key={toggle.key}
          sx={{
            display: "grid",
            gridTemplateRows: "auto auto 30px auto",
            border: "1px solid",
            borderColor: toggle.value ? "primary.main" : "divider",
            borderRadius: "4px",
            mb: 1,
          }}
        >
          <Box
            component="label"
            sx={{ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center" }}
          >
            <Tooltip title={configTranslation(`toggles.${toggle.key}.name`)} placement="top" arrow>
              <Typography sx={ellipsis}>
                {configTranslation(`toggles.${toggle.key}.name`)}
              </Typography>
            </Tooltip>
            <Switch
              checked={
                // Make sure the value is a boolean
                // else return false
                typeof toggle.value === "boolean" ? toggle.value : false
              }
              onChange={() => {
                toggleFeatureFlag(toggle.key);
              }}
              data-testid={`toggles.${toggle.key}.name`}
            />
          </Box>
          <Typography
            variant="body1Medium"
            sx={!expandedDescriptions.includes(toggle.key) ? multiLineEllipsis(2) : null}
          >
            {configTranslation(`toggles.${toggle.key}.description`)}
          </Typography>
          <Link
            sx={{ cursor: "pointer", fontSize: "0.9rem", textDecoration: "none" }}
            onClick={() => {
              if (expandedDescriptions.includes(toggle.key)) {
                setExpandedDescriptions(
                  expandedDescriptions.filter((key) => {
                    return key !== toggle.key;
                  }),
                );
              } else {
                setExpandedDescriptions([...expandedDescriptions, toggle.key]);
              }
            }}
            iconEnd={
              expandedDescriptions.includes(toggle.key) ? <ChevronUpIcon /> : <ChevronDownIcon />
            }
          >
            {expandedDescriptions.includes(toggle.key)
              ? configTranslation("readLess")
              : configTranslation("readMore")}
          </Link>
        </ListItem>
      ))}
    </List>
  );
};
