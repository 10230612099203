type AvatarSvgProps = {
  svgLabel: string;
  fillColor: string;
  backgroundColor: string;
};

export const AvatarSvg: React.FC<AvatarSvgProps> = ({ svgLabel, fillColor, backgroundColor }) => (
  <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <title>{svgLabel}</title>
    <circle cx="25" cy="25" r="25" fill={backgroundColor} />
    <path
      d="M25 15.85C26.74 15.85 28.15 17.26 28.15 19C28.15 20.74 26.74 22.15 25 22.15C23.26 22.15 21.85 20.74 21.85 19C21.85 17.26 23.26 15.85 25 15.85ZM25 29.35C29.455 29.35 34.15 31.54 34.15 32.5V34.15H15.85V32.5C15.85 31.54 20.545 29.35 25 29.35ZM25 13C21.685 13 19 15.685 19 19C19 22.315 21.685 25 25 25C28.315 25 31 22.315 31 19C31 15.685 28.315 13 25 13ZM25 26.5C20.995 26.5 13 28.51 13 32.5V35.5C13 36.325 13.675 37 14.5 37H35.5C36.325 37 37 36.325 37 35.5V32.5C37 28.51 29.005 26.5 25 26.5Z"
      fill={fillColor}
    />
  </svg>
);
