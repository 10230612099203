import {
  useGetMethodFromGroupsToLearningUnitsQuery,
  useGetSubjectsAndMethodsQuery,
} from "@generated/graphql";

import { REACT_QUERY_OPTS_15MINSTALE } from "@lib/react-query";
import { getShouldUseDomainDiffRouteValue } from "@utils/use-should-use-domain-diff-route";
import { slugifyRoute } from "@utils/slugify";
import { useRouter } from "next/router";

export type SelectedNavigation = ReturnType<typeof useSelectedNavigation>["selected"];

export function useSelectedNavigation() {
  const router = useRouter();
  const subjectName: string | string[] | undefined = router.query.subject;

  const methodId: string | string[] | undefined = router.query.method;
  const methodGroupId: string | string[] | undefined = router.query["method-group"];
  const chapterId: string | string[] | undefined = router.query.chapter;
  const paragraphId: string | string[] | undefined = router.query.paragraph;
  const learningUnitId: string | string[] | undefined = router.query["learning-unit"];
  const versionId: string | string[] | undefined = router.query.versionId;

  const shouldUseDomainDiffRoute = getShouldUseDomainDiffRouteValue();

  const {
    isLoading: isLoadingSubjectsAndMethods,
    error: subjectAndMethodsError,
    data: subjectAndMethodsData,
  } = useGetSubjectsAndMethodsQuery(undefined, REACT_QUERY_OPTS_15MINSTALE);

  const selectedSubject = subjectAndMethodsData?.subjects.find(({ name }) => {
    return slugifyRoute(name).trim() === subjectName?.toString().trim();
  });

  const selectedMethod = selectedSubject?.methodsLicensed.find(({ id }) => {
    return slugifyRoute(id).trim() === methodId?.toString().trim();
  });

  const { isLoading, isFetching, error, data } = useGetMethodFromGroupsToLearningUnitsQuery(
    { id: selectedMethod?.id || "", withDomains: shouldUseDomainDiffRoute },
    { enabled: !!selectedMethod?.id, ...REACT_QUERY_OPTS_15MINSTALE },
  );

  const selectedMethodGroup = data?.method?.methodGroups?.find(({ id, umcConfig }) => {
    return (
      slugifyRoute(id).trim() === methodGroupId?.toString().trim() &&
      (umcConfig || versionId ? umcConfig?.id === versionId : true)
    );
  });

  const selectedChapter = selectedMethodGroup?.chapters?.find(({ id }) => {
    return slugifyRoute(id).trim() === chapterId?.toString().trim();
  });

  const selectedParagraph = selectedChapter?.paragraphs?.find(({ id }) => {
    return slugifyRoute(id).trim() === paragraphId?.toString().trim();
  });

  const selectedLearningUnit = selectedParagraph?.learningUnits?.find(({ id }) => {
    return slugifyRoute(id).trim() === learningUnitId?.toString().trim();
  });

  return {
    isLoading: isLoadingSubjectsAndMethods || (isLoading && isFetching),
    error: subjectAndMethodsError || error,
    selected: {
      selectedSubject,
      selectedMethod,
      selectedMethodGroup,
      selectedChapter,
      selectedParagraph,
      selectedLearningUnit,
    },
    methodGroups: data?.method?.methodGroups,
  };
}
