import { useFeatureFlag } from "@utils/use-feature-flag";
import { useRouter } from "next/router";

export const getShouldUseDomainDiffRouteValue = (): boolean => {
  const router = useRouter();
  const isDiffRouteWithDomainActive = useFeatureFlag("isDiffRouteWithDomainActive", false);
  const domainDiffRouteMethodsList = useFeatureFlag<string[]>("domainDiffRouteMethodsList", []);
  return (
    isDiffRouteWithDomainActive &&
    domainDiffRouteMethodsList.includes(router.query.method as string)
  );
};
