import { useToursModalOpen } from "@components/help-navigation/use-tours-modal-open";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@infinitaslearning/pixel-design-system";
// TODO: because of their use as bigger images, CircleBolt & CircleDashed need to be replaced by assets instead
import CircleBolt from "@infinitaslearning/pixel-icons/fa/light/CircleBolt";
import CircleDashed from "@infinitaslearning/pixel-icons/CircleDashed";
import { useBrand } from "@utils/use-brand";
import { useTranslation } from "next-i18next";

const TOURS_BUTTON_ICONS_SX = {
  width: "64px",
  height: "64px",
};

export const ToursModal = () => {
  const theme = useTheme();
  const { t } = useTranslation("common", { keyPrefix: "tours-modal" });
  const { opCo, locale } = useBrand();

  const { isToursModalOpen, setIsToursModalOpen } = useToursModalOpen((state) => ({
    isToursModalOpen: state.isToursModalOpen,
    setIsToursModalOpen: state.setIsToursModalOpen,
  }));

  return (
    <Dialog open={isToursModalOpen} onClose={() => setIsToursModalOpen(false)} maxWidth={"sm"}>
      <DialogTitle>{t("title")}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", gap: theme.spacing(2) }}>
          {opCo !== "liber" && (
            <Card
              sx={{ borderRadius: theme.spacing(2) }}
              onClick={() => setIsToursModalOpen(false)}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: theme.spacing(2),
                }}
                data-usetiful={`recap-onboarding-button-user-${opCo}-${locale}`}
              >
                <CardMedia>
                  <CircleBolt
                    htmlColor={theme.pixel.color.primary.key}
                    sx={{ ...TOURS_BUTTON_ICONS_SX }}
                  />
                </CardMedia>
                <CardContent>
                  <Typography variant="body1Bold">{t("recap-button-title")}</Typography>
                  <Typography variant="body1Light">{t("recap-button-description")}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
          <Card sx={{ borderRadius: theme.spacing(2) }} onClick={() => setIsToursModalOpen(false)}>
            <CardActionArea
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                borderRadius: theme.spacing(2),
              }}
              data-usetiful={`scratch-onboarding-button-user-${opCo}-${locale}`}
            >
              <CardMedia>
                <CircleDashed
                  htmlColor={theme.pixel.color.primary.key}
                  sx={{ ...TOURS_BUTTON_ICONS_SX }}
                />
              </CardMedia>
              <CardContent>
                <Typography variant="body1Bold">{t("scratch-button-title")}</Typography>
                <Typography variant="body1Light">{t("scratch-button-description")}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
