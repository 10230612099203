import type { NextRouter } from "next/router";

export const MAIN_ROUTES = {
  home: "/",
  content: "/content",
  evaluationOverview: "/evaluation-overview",
  editEvaluation: "/edit-evaluation",
  evaluationGrowth: "/evaluation-growth",
  admin: "/admin",
  adminNew: "/admin/new",
  tasksManager: "/tasks-manager",
};

export const SUB_ROUTES = { contentOverview: "/overview" };

export const NEWS_ROUTE = "/news";

export function getPositiveNumberParameterFromRoute(
  router: NextRouter,
  parameterName: string,
  maxValue: number,
): number {
  const parameterValue = Number(router.query[parameterName]);
  const isParameterWithinBoundaries = parameterValue > 0 && parameterValue < maxValue;
  const actualValue =
    !isNaN(parameterValue) && Number.isInteger(parameterValue) && isParameterWithinBoundaries
      ? parameterValue
      : 0;
  return actualValue;
}
