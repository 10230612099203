const translationMapEvaluation = {
  common: {
    attachment: "evaluation:common.attachment",
    back: "evaluation:common.back",
    cancel: "evaluation:common.cancel",
    difficulty: "evaluation:common.difficulty",
    dontSave: "evaluation:common.dontSave",
    download: "evaluation:common.download",
    effort: "evaluation:common.effort",
    impact: "evaluation:common.impact",
    next: "evaluation:common.next",
    openLink: "evaluation:common.openLink",
    save: "evaluation:common.save",
    search: "evaluation:common.search",
    selectAll: "evaluation:common.selectAll",
    studentsEmptyState: {
      description: "evaluation:common.studentsEmptyState.description",
      title: "evaluation:common.studentsEmptyState.title",
    },
  },
  createNewAction: {
    addDescription: "evaluation:createNewAction.addDescription",
    addNote: "evaluation:createNewAction.addNote",
    allClasses: "evaluation:createNewAction.allClasses",
    assignActionLabel: "evaluation:createNewAction.assignActionLabel",
    assignActionTitle: "evaluation:createNewAction.assignActionTitle",
    classroomAccordionSubtitle: "evaluation:createNewAction.classroomAccordionSubtitle",
    classroomAccordionTitle: "evaluation:createNewAction.classroomAccordionTitle",
    createAction: "evaluation:createNewAction.createAction",
    createCustomAction: "evaluation:createNewAction.createCustomAction",
    createOwnCustomAction: "evaluation:createNewAction.createOwnCustomAction",
    customAction: "evaluation:createNewAction.customAction",
    customActionHelpText: "evaluation:createNewAction.customActionHelpText",
    customHelpText: "evaluation:createNewAction.customHelpText",
    datePicker: {
      cancelDates: "evaluation:createNewAction.datePicker.cancelDates",
      shortcuts: {
        next7days: "evaluation:createNewAction.datePicker.shortcuts.next7days",
        thisMonth: "evaluation:createNewAction.datePicker.shortcuts.thisMonth",
        thisWeek: "evaluation:createNewAction.datePicker.shortcuts.thisWeek",
        today: "evaluation:createNewAction.datePicker.shortcuts.today",
      },
    },
    didacticKey: "evaluation:createNewAction.didacticKey",
    emptyClassroom: "evaluation:createNewAction.emptyClassroom",
    fileUploadHelpText: "evaluation:createNewAction.fileUploadHelpText",
    filterHelpText: "evaluation:createNewAction.filterHelpText",
    loadStudents: "evaluation:createNewAction.loadStudents",
    next: "evaluation:createNewAction.next",
    noteText: "evaluation:createNewAction.noteText",
    planAction: "evaluation:createNewAction.planAction",
    predefined: "evaluation:createNewAction.predefined",
    previous: "evaluation:createNewAction.previous",
    pupilsSelected: "evaluation:createNewAction.pupilsSelected",
    schedule: "evaluation:createNewAction.schedule",
    scheduleText: "evaluation:createNewAction.scheduleText",
    selectAll: "evaluation:createNewAction.selectAll",
    selectAllClasses: "evaluation:createNewAction.selectAllClasses",
    selectAnAction: "evaluation:createNewAction.selectAnAction",
    selectAssignee: "evaluation:createNewAction.selectAssignee",
    selectClass: "evaluation:createNewAction.selectClass",
    selectDate: "evaluation:createNewAction.selectDate",
    subAspect: "evaluation:createNewAction.subAspect",
    suggestions: {
      classroom: "evaluation:createNewAction.suggestions.classroom",
      emptyState: {
        defaultDescription: "evaluation:createNewAction.suggestions.emptyState.defaultDescription",
        defaultTitle: "evaluation:createNewAction.suggestions.emptyState.defaultTitle",
        scoreDescription: "evaluation:createNewAction.suggestions.emptyState.scoreDescription",
        scoreTitle: "evaluation:createNewAction.suggestions.emptyState.scoreTitle",
        wideviewDescription:
          "evaluation:createNewAction.suggestions.emptyState.wideviewDescription",
        wideviewTitle: "evaluation:createNewAction.suggestions.emptyState.wideviewTitle",
      },
      info: "evaluation:createNewAction.suggestions.info",
      period: "evaluation:createNewAction.suggestions.period",
      periods: {
        march: "evaluation:createNewAction.suggestions.periods.march",
        october: "evaluation:createNewAction.suggestions.periods.october",
        schoolYear: "evaluation:createNewAction.suggestions.periods.schoolYear",
      },
      students: "evaluation:createNewAction.suggestions.students",
    },
    targetAudience: "evaluation:createNewAction.targetAudience",
    title: "evaluation:createNewAction.title",
    typeSelectorTitle: "evaluation:createNewAction.typeSelectorTitle",
    uploadAttachment: "evaluation:createNewAction.uploadAttachment",
    uploadText: "evaluation:createNewAction.uploadText",
  },
  createNewEvaluation: {
    attachmentTabLabel: "evaluation:createNewEvaluation.attachmentTabLabel",
    buildingTemplateButton: "evaluation:createNewEvaluation.buildingTemplateButton",
    buildingTemplateError: "evaluation:createNewEvaluation.buildingTemplateError",
    classroomSelectorLabel: "evaluation:createNewEvaluation.classroomSelectorLabel",
    contentTabLabel: "evaluation:createNewEvaluation.contentTabLabel",
    detailsTabLabel: "evaluation:createNewEvaluation.detailsTabLabel",
    differentiation: "evaluation:createNewEvaluation.differentiation",
    difficulty: "evaluation:createNewEvaluation.difficulty",
    downloadTemplateButton: "evaluation:createNewEvaluation.downloadTemplateButton",
    duration: "evaluation:createNewEvaluation.duration",
    effort: "evaluation:createNewEvaluation.effort",
    emptyMessageLineOne: "evaluation:createNewEvaluation.emptyMessageLineOne",
    emptyMessageLineTwo: "evaluation:createNewEvaluation.emptyMessageLineTwo",
    evaluationSteps: {
      stepOneName: "evaluation:createNewEvaluation.evaluationSteps.stepOneName",
      stepThreeName: "evaluation:createNewEvaluation.evaluationSteps.stepThreeName",
      stepTwoName: "evaluation:createNewEvaluation.evaluationSteps.stepTwoName",
    },
    evaluationTypeTitle: "evaluation:createNewEvaluation.evaluationTypeTitle",
    fillInEvaluation: "evaluation:createNewEvaluation.fillInEvaluation",
    generalInformationTitle: "evaluation:createNewEvaluation.generalInformationTitle",
    goBack: "evaluation:createNewEvaluation.goBack",
    heading: "evaluation:createNewEvaluation.heading",
    impact: "evaluation:createNewEvaluation.impact",
    instructionLabel: "evaluation:createNewEvaluation.instructionLabel",
    materialsLabel: "evaluation:createNewEvaluation.materialsLabel",
    minuteLabel: "evaluation:createNewEvaluation.minuteLabel",
    newEvaluation: "evaluation:createNewEvaluation.newEvaluation",
    nextButton: "evaluation:createNewEvaluation.nextButton",
    noAttachments: "evaluation:createNewEvaluation.noAttachments",
    notes: "evaluation:createNewEvaluation.notes",
    previousButton: "evaluation:createNewEvaluation.previousButton",
    pupilsHeader: "evaluation:createNewEvaluation.pupilsHeader",
    reasonLabel: "evaluation:createNewEvaluation.reasonLabel",
    saveEvaluation: "evaluation:createNewEvaluation.saveEvaluation",
    saveWarningModal: {
      content: "evaluation:createNewEvaluation.saveWarningModal.content",
      title: "evaluation:createNewEvaluation.saveWarningModal.title",
    },
    selectAllClasses: "evaluation:createNewEvaluation.selectAllClasses",
    selectAllStudent: "evaluation:createNewEvaluation.selectAllStudent",
    selectWideview: "evaluation:createNewEvaluation.selectWideview",
    snackbar: {
      create: {
        description: "evaluation:createNewEvaluation.snackbar.create.description",
        title: "evaluation:createNewEvaluation.snackbar.create.title",
      },
      export: {
        description: "evaluation:createNewEvaluation.snackbar.export.description",
        title: "evaluation:createNewEvaluation.snackbar.export.title",
      },
    },
    status: {
      complete: "evaluation:createNewEvaluation.status.complete",
      draft: "evaluation:createNewEvaluation.status.draft",
      incomplete: "evaluation:createNewEvaluation.status.incomplete",
    },
    stepOneHeader: "evaluation:createNewEvaluation.stepOneHeader",
    studentSelected: "evaluation:createNewEvaluation.studentSelected",
    submissionHeader: "evaluation:createNewEvaluation.submissionHeader",
    submissionTabLabel: "evaluation:createNewEvaluation.submissionTabLabel",
    timeInfo1: "evaluation:createNewEvaluation.timeInfo1",
    timeInfo2: "evaluation:createNewEvaluation.timeInfo2",
    timeInfo3: "evaluation:createNewEvaluation.timeInfo3",
    type: "evaluation:createNewEvaluation.type",
    useThisEvaluation: "evaluation:createNewEvaluation.useThisEvaluation",
    wvtEvaluationStep: {
      students: "evaluation:createNewEvaluation.wvtEvaluationStep.students",
      title: "evaluation:createNewEvaluation.wvtEvaluationStep.title",
    },
  },
  detailAction: {
    changedBy: "evaluation:detailAction.changedBy",
    creationDate: "evaluation:detailAction.creationDate",
    description: "evaluation:detailAction.description",
    example: "evaluation:detailAction.example",
    lastChanged: "evaluation:detailAction.lastChanged",
    noDescription: "evaluation:detailAction.noDescription",
    noNotes: "evaluation:detailAction.noNotes",
    ownAttachments: "evaluation:detailAction.ownAttachments",
    predefinedAttachments: "evaluation:detailAction.predefinedAttachments",
    readLess: "evaluation:detailAction.readLess",
    readMore: "evaluation:detailAction.readMore",
    studentsOverview: "evaluation:detailAction.studentsOverview",
  },
  didacticKeys: {
    didacticKey1: "evaluation:didacticKeys.didacticKey1",
    didacticKey2: "evaluation:didacticKeys.didacticKey2",
    didacticKey3: "evaluation:didacticKeys.didacticKey3",
    didacticKey4: "evaluation:didacticKeys.didacticKey4",
    didacticKey5: "evaluation:didacticKeys.didacticKey5",
  },
  editEvaluation: {
    heading: "evaluation:editEvaluation.heading",
    removeAllStudentWarningModal: {
      confirm: "evaluation:editEvaluation.removeAllStudentWarningModal.confirm",
      content: "evaluation:editEvaluation.removeAllStudentWarningModal.content",
      title: "evaluation:editEvaluation.removeAllStudentWarningModal.title",
    },
    removeStudentWarningModal: {
      confirm: "evaluation:editEvaluation.removeStudentWarningModal.confirm",
      content: "evaluation:editEvaluation.removeStudentWarningModal.content",
      title: "evaluation:editEvaluation.removeStudentWarningModal.title",
    },
    snackbar: {
      edit: {
        description: "evaluation:editEvaluation.snackbar.edit.description",
        title: "evaluation:editEvaluation.snackbar.edit.title",
      },
    },
  },
  evaluationGrowthOverview: {
    actionsTitle: "evaluation:evaluationGrowthOverview.actionsTitle",
    assigneeCount: "evaluation:evaluationGrowthOverview.assigneeCount",
    assignees: "evaluation:evaluationGrowthOverview.assignees",
    createButton: "evaluation:evaluationGrowthOverview.createButton",
    emptyState: {
      description: "evaluation:evaluationGrowthOverview.emptyState.description",
      title: "evaluation:evaluationGrowthOverview.emptyState.title",
    },
    filter: {
      date: {
        month: "evaluation:evaluationGrowthOverview.filter.date.month",
        schoolYear: "evaluation:evaluationGrowthOverview.filter.date.schoolYear",
        today: "evaluation:evaluationGrowthOverview.filter.date.today",
        week: "evaluation:evaluationGrowthOverview.filter.date.week",
      },
      state: {
        allActions: "evaluation:evaluationGrowthOverview.filter.state.allActions",
        selectState: "evaluation:evaluationGrowthOverview.filter.state.selectState",
      },
      student: {
        allStudents: "evaluation:evaluationGrowthOverview.filter.student.allStudents",
      },
    },
    graphs: {
      classLevelEvaluationGraph: {
        XAxisLabel:
          "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.XAxisLabel",
        YAxisLabel:
          "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.YAxisLabel",
        emptyState: {
          subTitle:
            "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.emptyState.subTitle",
          title:
            "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.emptyState.title",
        },
        modal: {
          flandersAverage: {
            description:
              "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.flandersAverage.description",
            title:
              "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.flandersAverage.title",
          },
          okiReferences: {
            description:
              "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.okiReferences.description",
            title:
              "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.okiReferences.title",
          },
          scores: {
            classScreeningGraph: {
              description:
                "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.scores.classScreeningGraph.description",
              title:
                "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.scores.classScreeningGraph.title",
            },
            screeningGraph: {
              description:
                "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.scores.screeningGraph.description",
              title:
                "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.scores.screeningGraph.title",
            },
            subaspectGraph: {
              description:
                "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.scores.subaspectGraph.description",
              title:
                "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.modal.scores.subaspectGraph.title",
            },
          },
        },
        studentCount:
          "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.studentCount",
        subTitle: "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.subTitle",
        title: "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.title",
        tooltip: {
          average:
            "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.tooltip.average",
          classAverage:
            "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.tooltip.classAverage",
          pupilsCount:
            "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.tooltip.pupilsCount",
          studentAverage:
            "evaluation:evaluationGrowthOverview.graphs.classLevelEvaluationGraph.tooltip.studentAverage",
        },
      },
      filters: {
        applyButton: "evaluation:evaluationGrowthOverview.graphs.filters.applyButton",
        learningYearSelector: {
          label: "evaluation:evaluationGrowthOverview.graphs.filters.learningYearSelector.label",
          options: {
            learningYear1:
              "evaluation:evaluationGrowthOverview.graphs.filters.learningYearSelector.options.learningYear1",
            learningYear2:
              "evaluation:evaluationGrowthOverview.graphs.filters.learningYearSelector.options.learningYear2",
            learningYear3:
              "evaluation:evaluationGrowthOverview.graphs.filters.learningYearSelector.options.learningYear3",
            learningYear4:
              "evaluation:evaluationGrowthOverview.graphs.filters.learningYearSelector.options.learningYear4",
            learningYear5:
              "evaluation:evaluationGrowthOverview.graphs.filters.learningYearSelector.options.learningYear5",
            learningYear6:
              "evaluation:evaluationGrowthOverview.graphs.filters.learningYearSelector.options.learningYear6",
          },
          placeholder:
            "evaluation:evaluationGrowthOverview.graphs.filters.learningYearSelector.placeholder",
        },
        okiGroupSelector: {
          descriptions: {
            group1:
              "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.descriptions.group1",
            group2:
              "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.descriptions.group2",
            group3:
              "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.descriptions.group3",
          },
          helpText: "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.helpText",
          label: "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.label",
          options: {
            empty:
              "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.options.empty",
            group1:
              "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.options.group1",
            group2:
              "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.options.group2",
            group3:
              "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.options.group3",
          },
          tooltip: "evaluation:evaluationGrowthOverview.graphs.filters.okiGroupSelector.tooltip",
        },
        periodSelector: {
          label: "evaluation:evaluationGrowthOverview.graphs.filters.periodSelector.label",
          options: {
            marchPeriod:
              "evaluation:evaluationGrowthOverview.graphs.filters.periodSelector.options.marchPeriod",
            octoberPeriod:
              "evaluation:evaluationGrowthOverview.graphs.filters.periodSelector.options.octoberPeriod",
          },
        },
        resetButton: "evaluation:evaluationGrowthOverview.graphs.filters.resetButton",
        screeningTestSelector: {
          label: "evaluation:evaluationGrowthOverview.graphs.filters.screeningTestSelector.label",
          placeholder:
            "evaluation:evaluationGrowthOverview.graphs.filters.screeningTestSelector.placeholder",
        },
        syncTime: "evaluation:evaluationGrowthOverview.graphs.filters.syncTime",
        title: "evaluation:evaluationGrowthOverview.graphs.filters.title",
      },
      screeningsGraphs: {
        aggregatedClassLevel: {
          allScreening:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.allScreening",
          averageClass:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.averageClass",
          classAverage:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.classAverage",
          classAverageLabel:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.classAverageLabel",
          emptyState: {
            subTitle:
              "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.emptyState.subTitle",
            title:
              "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.emptyState.title",
          },
          excludeTestMessage:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.excludeTestMessage",
          flanders:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.flanders",
          flandersAverage:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.flandersAverage",
          okiReferenceAverage:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.okiReferenceAverage",
          pupilsCount:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.pupilsCount",
          scores:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.scores",
          screeningsCount:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.screeningsCount",
          studentAverage:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.studentAverage",
          studentCount:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.studentCount",
          subTitle:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.subTitle",
          title:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.aggregatedClassLevel.title",
        },
        emptyState: {
          periodTitle:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.emptyState.periodTitle",
          subTitle:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.emptyState.subTitle",
          title: "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.emptyState.title",
        },
        perScreeningsGraph: {
          classAverage:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.classAverage",
          flanders:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.flanders",
          flandersAverage:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.flandersAverage",
          pupilsCount:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.pupilsCount",
          scores:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.scores",
          screeningTitle:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.screeningTitle",
          screenings:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.screenings",
          screeningsCount:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.screeningsCount",
          selector: {
            allScreenings:
              "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.selector.allScreenings",
            learningYear:
              "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.selector.learningYear",
            selectScreenings:
              "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.selector.selectScreenings",
          },
          studentCount:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.studentCount",
          subTitle:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.subTitle",
          title:
            "evaluation:evaluationGrowthOverview.graphs.screeningsGraphs.perScreeningsGraph.title",
        },
      },
      subaspectGraph: {
        legend: {
          firstSemesterAverage:
            "evaluation:evaluationGrowthOverview.graphs.subaspectGraph.legend.firstSemesterAverage",
          schoolYearAverage:
            "evaluation:evaluationGrowthOverview.graphs.subaspectGraph.legend.schoolYearAverage",
          secondSemesterAverage:
            "evaluation:evaluationGrowthOverview.graphs.subaspectGraph.legend.secondSemesterAverage",
        },
        types: {
          screenings: "evaluation:evaluationGrowthOverview.graphs.subaspectGraph.types.screenings",
          selector: {
            allTypes:
              "evaluation:evaluationGrowthOverview.graphs.subaspectGraph.types.selector.allTypes",
            selectTypes:
              "evaluation:evaluationGrowthOverview.graphs.subaspectGraph.types.selector.selectTypes",
          },
          wideviews: "evaluation:evaluationGrowthOverview.graphs.subaspectGraph.types.wideviews",
        },
      },
    },
    menu: {
      addToCatalog: "evaluation:evaluationGrowthOverview.menu.addToCatalog",
      delete: "evaluation:evaluationGrowthOverview.menu.delete",
      edit: "evaluation:evaluationGrowthOverview.menu.edit",
      export: "evaluation:evaluationGrowthOverview.menu.export",
      markAsCompleted: "evaluation:evaluationGrowthOverview.menu.markAsCompleted",
      removeFromCatalog: "evaluation:evaluationGrowthOverview.menu.removeFromCatalog",
      reopenAction: "evaluation:evaluationGrowthOverview.menu.reopenAction",
      view: "evaluation:evaluationGrowthOverview.menu.view",
    },
    modal: {
      catalog: {
        actionSelectionStep: {
          attachments:
            "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.attachments",
          customAction:
            "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.customAction",
          didacticKey:
            "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.didacticKey",
          emptyState: {
            custom: {
              button:
                "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.emptyState.custom.button",
              description:
                "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.emptyState.custom.description",
              title:
                "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.emptyState.custom.title",
            },
            favorite: {
              description:
                "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.emptyState.favorite.description",
              title:
                "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.emptyState.favorite.title",
            },
            filter: {
              button:
                "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.emptyState.filter.button",
              description:
                "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.emptyState.filter.description",
              title:
                "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.emptyState.filter.title",
            },
          },
          noAttachments:
            "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.noAttachments",
          scheduleAction:
            "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.scheduleAction",
          subaspect:
            "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.subaspect",
          title: "evaluation:evaluationGrowthOverview.modal.catalog.actionSelectionStep.title",
        },
        addToCatalogStep: {
          button: "evaluation:evaluationGrowthOverview.modal.catalog.addToCatalogStep.button",
          title: "evaluation:evaluationGrowthOverview.modal.catalog.addToCatalogStep.title",
        },
        customActionStep: {
          editTitle: "evaluation:evaluationGrowthOverview.modal.catalog.customActionStep.editTitle",
          markAsCatalogItem:
            "evaluation:evaluationGrowthOverview.modal.catalog.customActionStep.markAsCatalogItem",
          predefinedTitle:
            "evaluation:evaluationGrowthOverview.modal.catalog.customActionStep.predefinedTitle",
          title: "evaluation:evaluationGrowthOverview.modal.catalog.customActionStep.title",
        },
        studentSelectionStep: {
          predefinedTitle:
            "evaluation:evaluationGrowthOverview.modal.catalog.studentSelectionStep.predefinedTitle",
          title: "evaluation:evaluationGrowthOverview.modal.catalog.studentSelectionStep.title",
        },
        types: {
          all: "evaluation:evaluationGrowthOverview.modal.catalog.types.all",
          custom: "evaluation:evaluationGrowthOverview.modal.catalog.types.custom",
          favorite: "evaluation:evaluationGrowthOverview.modal.catalog.types.favorite",
          predefined: "evaluation:evaluationGrowthOverview.modal.catalog.types.predefined",
          suggestions: "evaluation:evaluationGrowthOverview.modal.catalog.types.suggestions",
        },
      },
      close: {
        allStudents: "evaluation:evaluationGrowthOverview.modal.close.allStudents",
        applyToAllStudents: "evaluation:evaluationGrowthOverview.modal.close.applyToAllStudents",
        button: "evaluation:evaluationGrowthOverview.modal.close.button",
        description: "evaluation:evaluationGrowthOverview.modal.close.description",
        title: "evaluation:evaluationGrowthOverview.modal.close.title",
      },
      delete: {
        button: "evaluation:evaluationGrowthOverview.modal.delete.button",
        description: "evaluation:evaluationGrowthOverview.modal.delete.description",
        title: "evaluation:evaluationGrowthOverview.modal.delete.title",
      },
      removeFromCatalog: {
        button: "evaluation:evaluationGrowthOverview.modal.removeFromCatalog.button",
        description: "evaluation:evaluationGrowthOverview.modal.removeFromCatalog.description",
        title: "evaluation:evaluationGrowthOverview.modal.removeFromCatalog.title",
      },
      reopen: {
        allStudents: "evaluation:evaluationGrowthOverview.modal.reopen.allStudents",
        applyToAllStudents: "evaluation:evaluationGrowthOverview.modal.reopen.applyToAllStudents",
        button: "evaluation:evaluationGrowthOverview.modal.reopen.button",
        dateContentSubTitle: "evaluation:evaluationGrowthOverview.modal.reopen.dateContentSubTitle",
        dateContentTitle: "evaluation:evaluationGrowthOverview.modal.reopen.dateContentTitle",
        description: "evaluation:evaluationGrowthOverview.modal.reopen.description",
        title: "evaluation:evaluationGrowthOverview.modal.reopen.title",
      },
    },
    noAssignees: "evaluation:evaluationGrowthOverview.noAssignees",
    pupilCount: "evaluation:evaluationGrowthOverview.pupilCount",
    screenings: "evaluation:evaluationGrowthOverview.screenings",
    showActions: "evaluation:evaluationGrowthOverview.showActions",
    showExpiredActions: "evaluation:evaluationGrowthOverview.showExpiredActions",
    snackbar: {
      addToCatalog: {
        button: "evaluation:evaluationGrowthOverview.snackbar.addToCatalog.button",
        description: "evaluation:evaluationGrowthOverview.snackbar.addToCatalog.description",
        title: "evaluation:evaluationGrowthOverview.snackbar.addToCatalog.title",
      },
      close: {
        description: "evaluation:evaluationGrowthOverview.snackbar.close.description",
        title: "evaluation:evaluationGrowthOverview.snackbar.close.title",
      },
      create: {
        description: "evaluation:evaluationGrowthOverview.snackbar.create.description",
        title: "evaluation:evaluationGrowthOverview.snackbar.create.title",
      },
      delete: {
        description: "evaluation:evaluationGrowthOverview.snackbar.delete.description",
        title: "evaluation:evaluationGrowthOverview.snackbar.delete.title",
      },
      edit: {
        description: "evaluation:evaluationGrowthOverview.snackbar.edit.description",
        title: "evaluation:evaluationGrowthOverview.snackbar.edit.title",
      },
      export: {
        description: "evaluation:evaluationGrowthOverview.snackbar.export.description",
        title: "evaluation:evaluationGrowthOverview.snackbar.export.title",
      },
      removeFromCatalog: {
        description: "evaluation:evaluationGrowthOverview.snackbar.removeFromCatalog.description",
        title: "evaluation:evaluationGrowthOverview.snackbar.removeFromCatalog.title",
      },
      reopen: {
        description: "evaluation:evaluationGrowthOverview.snackbar.reopen.description",
        title: "evaluation:evaluationGrowthOverview.snackbar.reopen.title",
      },
    },
    status: {
      completed: "evaluation:evaluationGrowthOverview.status.completed",
      expired: "evaluation:evaluationGrowthOverview.status.expired",
      reopened: "evaluation:evaluationGrowthOverview.status.reopened",
      scheduled: "evaluation:evaluationGrowthOverview.status.scheduled",
    },
    title: "evaluation:evaluationGrowthOverview.title",
  },
  export: {
    footnote: "evaluation:export.footnote",
    selectedOption: "evaluation:export.selectedOption",
    subtitle: "evaluation:export.subtitle",
    title: "evaluation:export.title",
  },
  exportAction: {
    attachments: "evaluation:exportAction.attachments",
    class: "evaluation:exportAction.class",
    content: "evaluation:exportAction.content",
    createDate: "evaluation:exportAction.createDate",
    createdBy: "evaluation:exportAction.createdBy",
    dates: "evaluation:exportAction.dates",
    description: "evaluation:exportAction.description",
    details: "evaluation:exportAction.details",
    download: "evaluation:exportAction.download",
    example: "evaluation:exportAction.example",
    noAttachmentsAvailable: "evaluation:exportAction.noAttachmentsAvailable",
    noDescription: "evaluation:exportAction.noDescription",
    note: "evaluation:exportAction.note",
    openLink: "evaluation:exportAction.openLink",
    ownAttachments: "evaluation:exportAction.ownAttachments",
    predefinedAttachments: "evaluation:exportAction.predefinedAttachments",
    studentsOverview: "evaluation:exportAction.studentsOverview",
    updateDate: "evaluation:exportAction.updateDate",
    updatedBy: "evaluation:exportAction.updatedBy",
  },
  exportEvaluation: {
    footnote: "evaluation:exportEvaluation.footnote",
    selectedOption: "evaluation:exportEvaluation.selectedOption",
    subtitle: "evaluation:exportEvaluation.subtitle",
    title: "evaluation:exportEvaluation.title",
  },
  overview: {
    createButton: "evaluation:overview.createButton",
    emptyState: {
      descriptionPartOne: "evaluation:overview.emptyState.descriptionPartOne",
      descriptionPartTwo: "evaluation:overview.emptyState.descriptionPartTwo",
      title: "evaluation:overview.emptyState.title",
    },
    evaluation: "evaluation:overview.evaluation",
    growth: "evaluation:overview.growth",
    modal: {
      delete: {
        button: "evaluation:overview.modal.delete.button",
        description: "evaluation:overview.modal.delete.description",
        title: "evaluation:overview.modal.delete.title",
      },
      download: {
        button: "evaluation:overview.modal.download.button",
        description: "evaluation:overview.modal.download.description",
        selectAllStudent: "evaluation:overview.modal.download.selectAllStudent",
        title: "evaluation:overview.modal.download.title",
      },
      preview: {
        button: "evaluation:overview.modal.preview.button",
        emptyResult: "evaluation:overview.modal.preview.emptyResult",
        name: "evaluation:overview.modal.preview.name",
        score: "evaluation:overview.modal.preview.score",
        title: "evaluation:overview.modal.preview.title",
      },
    },
    snackbar: {
      delete: {
        description: "evaluation:overview.snackbar.delete.description",
        title: "evaluation:overview.snackbar.delete.title",
      },
      export: {
        description: "evaluation:overview.snackbar.export.description",
        title: "evaluation:overview.snackbar.export.title",
      },
    },
    table: {
      columns: {
        description: "evaluation:overview.table.columns.description",
        status: "evaluation:overview.table.columns.status",
        type: "evaluation:overview.table.columns.type",
      },
      itemCount: "evaluation:overview.table.itemCount",
      itemsPerPage: "evaluation:overview.table.itemsPerPage",
      status: {
        complete: "evaluation:overview.table.status.complete",
        incomplete: "evaluation:overview.table.status.incomplete",
      },
      tooltip: {
        delete: "evaluation:overview.table.tooltip.delete",
        download: "evaluation:overview.table.tooltip.download",
        edit: "evaluation:overview.table.tooltip.edit",
        preview: "evaluation:overview.table.tooltip.preview",
      },
      type: {
        deepening: "evaluation:overview.table.type.deepening",
        deeper: "evaluation:overview.table.type.deeper",
        interview: "evaluation:overview.table.type.interview",
        observation: "evaluation:overview.table.type.observation",
        questionnaire: "evaluation:overview.table.type.questionnaire",
        widening: "evaluation:overview.table.type.widening",
        wider: "evaluation:overview.table.type.wider",
        workform: "evaluation:overview.table.type.workform",
      },
    },
    title: "evaluation:overview.title",
  },
  predefinedActions: {
    A100Example: "evaluation:predefinedActions.A100Example",
    A100GeneralTip: "evaluation:predefinedActions.A100GeneralTip",
    A100Title: "evaluation:predefinedActions.A100Title",
    A101Example: "evaluation:predefinedActions.A101Example",
    A101GeneralTip: "evaluation:predefinedActions.A101GeneralTip",
    A101Title: "evaluation:predefinedActions.A101Title",
    A102Example: "evaluation:predefinedActions.A102Example",
    A102GeneralTip: "evaluation:predefinedActions.A102GeneralTip",
    A102Title: "evaluation:predefinedActions.A102Title",
    A103Example: "evaluation:predefinedActions.A103Example",
    A103GeneralTip: "evaluation:predefinedActions.A103GeneralTip",
    A103Title: "evaluation:predefinedActions.A103Title",
    A104Example: "evaluation:predefinedActions.A104Example",
    A104GeneralTip: "evaluation:predefinedActions.A104GeneralTip",
    A104Title: "evaluation:predefinedActions.A104Title",
    A105Example: "evaluation:predefinedActions.A105Example",
    A105GeneralTip: "evaluation:predefinedActions.A105GeneralTip",
    A105Title: "evaluation:predefinedActions.A105Title",
    A106Example: "evaluation:predefinedActions.A106Example",
    A106GeneralTip: "evaluation:predefinedActions.A106GeneralTip",
    A106Title: "evaluation:predefinedActions.A106Title",
    A107Example: "evaluation:predefinedActions.A107Example",
    A107GeneralTip: "evaluation:predefinedActions.A107GeneralTip",
    A107Title: "evaluation:predefinedActions.A107Title",
    A108Example: "evaluation:predefinedActions.A108Example",
    A108GeneralTip: "evaluation:predefinedActions.A108GeneralTip",
    A108Title: "evaluation:predefinedActions.A108Title",
    A109Example: "evaluation:predefinedActions.A109Example",
    A109GeneralTip: "evaluation:predefinedActions.A109GeneralTip",
    A109Title: "evaluation:predefinedActions.A109Title",
    A10Example: "evaluation:predefinedActions.A10Example",
    A10GeneralTip: "evaluation:predefinedActions.A10GeneralTip",
    A10Title: "evaluation:predefinedActions.A10Title",
    A110Example: "evaluation:predefinedActions.A110Example",
    A110GeneralTip: "evaluation:predefinedActions.A110GeneralTip",
    A110Title: "evaluation:predefinedActions.A110Title",
    A111Example: "evaluation:predefinedActions.A111Example",
    A111GeneralTip: "evaluation:predefinedActions.A111GeneralTip",
    A111Title: "evaluation:predefinedActions.A111Title",
    A112Example: "evaluation:predefinedActions.A112Example",
    A112GeneralTip: "evaluation:predefinedActions.A112GeneralTip",
    A112Title: "evaluation:predefinedActions.A112Title",
    A113Example: "evaluation:predefinedActions.A113Example",
    A113GeneralTip: "evaluation:predefinedActions.A113GeneralTip",
    A113Title: "evaluation:predefinedActions.A113Title",
    A114Example: "evaluation:predefinedActions.A114Example",
    A114GeneralTip: "evaluation:predefinedActions.A114GeneralTip",
    A114Title: "evaluation:predefinedActions.A114Title",
    A115Example: "evaluation:predefinedActions.A115Example",
    A115GeneralTip: "evaluation:predefinedActions.A115GeneralTip",
    A115Title: "evaluation:predefinedActions.A115Title",
    A116Example: "evaluation:predefinedActions.A116Example",
    A116GeneralTip: "evaluation:predefinedActions.A116GeneralTip",
    A116Title: "evaluation:predefinedActions.A116Title",
    A117Example: "evaluation:predefinedActions.A117Example",
    A117GeneralTip: "evaluation:predefinedActions.A117GeneralTip",
    A117Title: "evaluation:predefinedActions.A117Title",
    A118Example: "evaluation:predefinedActions.A118Example",
    A118GeneralTip: "evaluation:predefinedActions.A118GeneralTip",
    A118Title: "evaluation:predefinedActions.A118Title",
    A119Example: "evaluation:predefinedActions.A119Example",
    A119GeneralTip: "evaluation:predefinedActions.A119GeneralTip",
    A119Title: "evaluation:predefinedActions.A119Title",
    A11Example: "evaluation:predefinedActions.A11Example",
    A11GeneralTip: "evaluation:predefinedActions.A11GeneralTip",
    A11Title: "evaluation:predefinedActions.A11Title",
    A120Example: "evaluation:predefinedActions.A120Example",
    A120GeneralTip: "evaluation:predefinedActions.A120GeneralTip",
    A120Title: "evaluation:predefinedActions.A120Title",
    A121Example: "evaluation:predefinedActions.A121Example",
    A121GeneralTip: "evaluation:predefinedActions.A121GeneralTip",
    A121Title: "evaluation:predefinedActions.A121Title",
    A122Example: "evaluation:predefinedActions.A122Example",
    A122GeneralTip: "evaluation:predefinedActions.A122GeneralTip",
    A122Title: "evaluation:predefinedActions.A122Title",
    A123Example: "evaluation:predefinedActions.A123Example",
    A123GeneralTip: "evaluation:predefinedActions.A123GeneralTip",
    A123Title: "evaluation:predefinedActions.A123Title",
    A124Example: "evaluation:predefinedActions.A124Example",
    A124GeneralTip: "evaluation:predefinedActions.A124GeneralTip",
    A124Title: "evaluation:predefinedActions.A124Title",
    A125Example: "evaluation:predefinedActions.A125Example",
    A125GeneralTip: "evaluation:predefinedActions.A125GeneralTip",
    A125Title: "evaluation:predefinedActions.A125Title",
    A126Example: "evaluation:predefinedActions.A126Example",
    A126GeneralTip: "evaluation:predefinedActions.A126GeneralTip",
    A126Title: "evaluation:predefinedActions.A126Title",
    A127Example: "evaluation:predefinedActions.A127Example",
    A127GeneralTip: "evaluation:predefinedActions.A127GeneralTip",
    A127Title: "evaluation:predefinedActions.A127Title",
    A128Example: "evaluation:predefinedActions.A128Example",
    A128GeneralTip: "evaluation:predefinedActions.A128GeneralTip",
    A128Title: "evaluation:predefinedActions.A128Title",
    A129Example: "evaluation:predefinedActions.A129Example",
    A129GeneralTip: "evaluation:predefinedActions.A129GeneralTip",
    A129Title: "evaluation:predefinedActions.A129Title",
    A12Example: "evaluation:predefinedActions.A12Example",
    A12GeneralTip: "evaluation:predefinedActions.A12GeneralTip",
    A12Title: "evaluation:predefinedActions.A12Title",
    A130Example: "evaluation:predefinedActions.A130Example",
    A130GeneralTip: "evaluation:predefinedActions.A130GeneralTip",
    A130Title: "evaluation:predefinedActions.A130Title",
    A131Example: "evaluation:predefinedActions.A131Example",
    A131GeneralTip: "evaluation:predefinedActions.A131GeneralTip",
    A131Title: "evaluation:predefinedActions.A131Title",
    A132Example: "evaluation:predefinedActions.A132Example",
    A132GeneralTip: "evaluation:predefinedActions.A132GeneralTip",
    A132Title: "evaluation:predefinedActions.A132Title",
    A133Example: "evaluation:predefinedActions.A133Example",
    A133GeneralTip: "evaluation:predefinedActions.A133GeneralTip",
    A133Title: "evaluation:predefinedActions.A133Title",
    A134Example: "evaluation:predefinedActions.A134Example",
    A134GeneralTip: "evaluation:predefinedActions.A134GeneralTip",
    A134Title: "evaluation:predefinedActions.A134Title",
    A135Example: "evaluation:predefinedActions.A135Example",
    A135GeneralTip: "evaluation:predefinedActions.A135GeneralTip",
    A135Title: "evaluation:predefinedActions.A135Title",
    A136Example: "evaluation:predefinedActions.A136Example",
    A136GeneralTip: "evaluation:predefinedActions.A136GeneralTip",
    A136Title: "evaluation:predefinedActions.A136Title",
    A137Example: "evaluation:predefinedActions.A137Example",
    A137GeneralTip: "evaluation:predefinedActions.A137GeneralTip",
    A137Title: "evaluation:predefinedActions.A137Title",
    A138Example: "evaluation:predefinedActions.A138Example",
    A138GeneralTip: "evaluation:predefinedActions.A138GeneralTip",
    A138Title: "evaluation:predefinedActions.A138Title",
    A139Example: "evaluation:predefinedActions.A139Example",
    A139GeneralTip: "evaluation:predefinedActions.A139GeneralTip",
    A139Title: "evaluation:predefinedActions.A139Title",
    A13Example: "evaluation:predefinedActions.A13Example",
    A13GeneralTip: "evaluation:predefinedActions.A13GeneralTip",
    A13Title: "evaluation:predefinedActions.A13Title",
    A140Example: "evaluation:predefinedActions.A140Example",
    A140GeneralTip: "evaluation:predefinedActions.A140GeneralTip",
    A140Title: "evaluation:predefinedActions.A140Title",
    A141Example: "evaluation:predefinedActions.A141Example",
    A141GeneralTip: "evaluation:predefinedActions.A141GeneralTip",
    A141Title: "evaluation:predefinedActions.A141Title",
    A142Example: "evaluation:predefinedActions.A142Example",
    A142GeneralTip: "evaluation:predefinedActions.A142GeneralTip",
    A142Title: "evaluation:predefinedActions.A142Title",
    A143Example: "evaluation:predefinedActions.A143Example",
    A143GeneralTip: "evaluation:predefinedActions.A143GeneralTip",
    A143Title: "evaluation:predefinedActions.A143Title",
    A144Example: "evaluation:predefinedActions.A144Example",
    A144GeneralTip: "evaluation:predefinedActions.A144GeneralTip",
    A144Title: "evaluation:predefinedActions.A144Title",
    A145Example: "evaluation:predefinedActions.A145Example",
    A145GeneralTip: "evaluation:predefinedActions.A145GeneralTip",
    A145Title: "evaluation:predefinedActions.A145Title",
    A146Example: "evaluation:predefinedActions.A146Example",
    A146GeneralTip: "evaluation:predefinedActions.A146GeneralTip",
    A146Title: "evaluation:predefinedActions.A146Title",
    A147Example: "evaluation:predefinedActions.A147Example",
    A147GeneralTip: "evaluation:predefinedActions.A147GeneralTip",
    A147Title: "evaluation:predefinedActions.A147Title",
    A148Example: "evaluation:predefinedActions.A148Example",
    A148GeneralTip: "evaluation:predefinedActions.A148GeneralTip",
    A148Title: "evaluation:predefinedActions.A148Title",
    A149Example: "evaluation:predefinedActions.A149Example",
    A149GeneralTip: "evaluation:predefinedActions.A149GeneralTip",
    A149Title: "evaluation:predefinedActions.A149Title",
    A14Example: "evaluation:predefinedActions.A14Example",
    A14GeneralTip: "evaluation:predefinedActions.A14GeneralTip",
    A14Title: "evaluation:predefinedActions.A14Title",
    A150Example: "evaluation:predefinedActions.A150Example",
    A150GeneralTip: "evaluation:predefinedActions.A150GeneralTip",
    A150Title: "evaluation:predefinedActions.A150Title",
    A15Example: "evaluation:predefinedActions.A15Example",
    A15GeneralTip: "evaluation:predefinedActions.A15GeneralTip",
    A15Title: "evaluation:predefinedActions.A15Title",
    A16Example: "evaluation:predefinedActions.A16Example",
    A16GeneralTip: "evaluation:predefinedActions.A16GeneralTip",
    A16Title: "evaluation:predefinedActions.A16Title",
    A17Example: "evaluation:predefinedActions.A17Example",
    A17GeneralTip: "evaluation:predefinedActions.A17GeneralTip",
    A17Title: "evaluation:predefinedActions.A17Title",
    A18Example: "evaluation:predefinedActions.A18Example",
    A18GeneralTip: "evaluation:predefinedActions.A18GeneralTip",
    A18Title: "evaluation:predefinedActions.A18Title",
    A19Example: "evaluation:predefinedActions.A19Example",
    A19GeneralTip: "evaluation:predefinedActions.A19GeneralTip",
    A19Title: "evaluation:predefinedActions.A19Title",
    A1Example: "evaluation:predefinedActions.A1Example",
    A1GeneralTip: "evaluation:predefinedActions.A1GeneralTip",
    A1Title: "evaluation:predefinedActions.A1Title",
    A20Example: "evaluation:predefinedActions.A20Example",
    A20GeneralTip: "evaluation:predefinedActions.A20GeneralTip",
    A20Title: "evaluation:predefinedActions.A20Title",
    A21Example: "evaluation:predefinedActions.A21Example",
    A21GeneralTip: "evaluation:predefinedActions.A21GeneralTip",
    A21Title: "evaluation:predefinedActions.A21Title",
    A22Example: "evaluation:predefinedActions.A22Example",
    A22GeneralTip: "evaluation:predefinedActions.A22GeneralTip",
    A22Title: "evaluation:predefinedActions.A22Title",
    A23Example: "evaluation:predefinedActions.A23Example",
    A23GeneralTip: "evaluation:predefinedActions.A23GeneralTip",
    A23Title: "evaluation:predefinedActions.A23Title",
    A24Example: "evaluation:predefinedActions.A24Example",
    A24GeneralTip: "evaluation:predefinedActions.A24GeneralTip",
    A24Title: "evaluation:predefinedActions.A24Title",
    A25Example: "evaluation:predefinedActions.A25Example",
    A25GeneralTip: "evaluation:predefinedActions.A25GeneralTip",
    A25Title: "evaluation:predefinedActions.A25Title",
    A26Example: "evaluation:predefinedActions.A26Example",
    A26GeneralTip: "evaluation:predefinedActions.A26GeneralTip",
    A26Title: "evaluation:predefinedActions.A26Title",
    A27Example: "evaluation:predefinedActions.A27Example",
    A27GeneralTip: "evaluation:predefinedActions.A27GeneralTip",
    A27Title: "evaluation:predefinedActions.A27Title",
    A28Example: "evaluation:predefinedActions.A28Example",
    A28GeneralTip: "evaluation:predefinedActions.A28GeneralTip",
    A28Title: "evaluation:predefinedActions.A28Title",
    A29Example: "evaluation:predefinedActions.A29Example",
    A29GeneralTip: "evaluation:predefinedActions.A29GeneralTip",
    A29Title: "evaluation:predefinedActions.A29Title",
    A2Example: "evaluation:predefinedActions.A2Example",
    A2GeneralTip: "evaluation:predefinedActions.A2GeneralTip",
    A2Title: "evaluation:predefinedActions.A2Title",
    A30Example: "evaluation:predefinedActions.A30Example",
    A30GeneralTip: "evaluation:predefinedActions.A30GeneralTip",
    A30Title: "evaluation:predefinedActions.A30Title",
    A31Example: "evaluation:predefinedActions.A31Example",
    A31GeneralTip: "evaluation:predefinedActions.A31GeneralTip",
    A31Title: "evaluation:predefinedActions.A31Title",
    A32Example: "evaluation:predefinedActions.A32Example",
    A32GeneralTip: "evaluation:predefinedActions.A32GeneralTip",
    A32Title: "evaluation:predefinedActions.A32Title",
    A33Example: "evaluation:predefinedActions.A33Example",
    A33GeneralTip: "evaluation:predefinedActions.A33GeneralTip",
    A33Title: "evaluation:predefinedActions.A33Title",
    A34Example: "evaluation:predefinedActions.A34Example",
    A34GeneralTip: "evaluation:predefinedActions.A34GeneralTip",
    A34Title: "evaluation:predefinedActions.A34Title",
    A35Example: "evaluation:predefinedActions.A35Example",
    A35GeneralTip: "evaluation:predefinedActions.A35GeneralTip",
    A35Title: "evaluation:predefinedActions.A35Title",
    A36Example: "evaluation:predefinedActions.A36Example",
    A36GeneralTip: "evaluation:predefinedActions.A36GeneralTip",
    A36Title: "evaluation:predefinedActions.A36Title",
    A37Example: "evaluation:predefinedActions.A37Example",
    A37GeneralTip: "evaluation:predefinedActions.A37GeneralTip",
    A37Title: "evaluation:predefinedActions.A37Title",
    A38Example: "evaluation:predefinedActions.A38Example",
    A38GeneralTip: "evaluation:predefinedActions.A38GeneralTip",
    A38Title: "evaluation:predefinedActions.A38Title",
    A39Example: "evaluation:predefinedActions.A39Example",
    A39GeneralTip: "evaluation:predefinedActions.A39GeneralTip",
    A39Title: "evaluation:predefinedActions.A39Title",
    A3Example: "evaluation:predefinedActions.A3Example",
    A3GeneralTip: "evaluation:predefinedActions.A3GeneralTip",
    A3Title: "evaluation:predefinedActions.A3Title",
    A40Example: "evaluation:predefinedActions.A40Example",
    A40GeneralTip: "evaluation:predefinedActions.A40GeneralTip",
    A40Title: "evaluation:predefinedActions.A40Title",
    A41Example: "evaluation:predefinedActions.A41Example",
    A41GeneralTip: "evaluation:predefinedActions.A41GeneralTip",
    A41Title: "evaluation:predefinedActions.A41Title",
    A42Example: "evaluation:predefinedActions.A42Example",
    A42GeneralTip: "evaluation:predefinedActions.A42GeneralTip",
    A42Title: "evaluation:predefinedActions.A42Title",
    A43Example: "evaluation:predefinedActions.A43Example",
    A43GeneralTip: "evaluation:predefinedActions.A43GeneralTip",
    A43Title: "evaluation:predefinedActions.A43Title",
    A44Example: "evaluation:predefinedActions.A44Example",
    A44GeneralTip: "evaluation:predefinedActions.A44GeneralTip",
    A44Title: "evaluation:predefinedActions.A44Title",
    A45Example: "evaluation:predefinedActions.A45Example",
    A45GeneralTip: "evaluation:predefinedActions.A45GeneralTip",
    A45Title: "evaluation:predefinedActions.A45Title",
    A46Example: "evaluation:predefinedActions.A46Example",
    A46GeneralTip: "evaluation:predefinedActions.A46GeneralTip",
    A46Title: "evaluation:predefinedActions.A46Title",
    A47Example: "evaluation:predefinedActions.A47Example",
    A47GeneralTip: "evaluation:predefinedActions.A47GeneralTip",
    A47Title: "evaluation:predefinedActions.A47Title",
    A48Example: "evaluation:predefinedActions.A48Example",
    A48GeneralTip: "evaluation:predefinedActions.A48GeneralTip",
    A48Title: "evaluation:predefinedActions.A48Title",
    A49Example: "evaluation:predefinedActions.A49Example",
    A49GeneralTip: "evaluation:predefinedActions.A49GeneralTip",
    A49Title: "evaluation:predefinedActions.A49Title",
    A4Example: "evaluation:predefinedActions.A4Example",
    A4GeneralTip: "evaluation:predefinedActions.A4GeneralTip",
    A4Title: "evaluation:predefinedActions.A4Title",
    A50Example: "evaluation:predefinedActions.A50Example",
    A50GeneralTip: "evaluation:predefinedActions.A50GeneralTip",
    A50Title: "evaluation:predefinedActions.A50Title",
    A51Example: "evaluation:predefinedActions.A51Example",
    A51GeneralTip: "evaluation:predefinedActions.A51GeneralTip",
    A51Title: "evaluation:predefinedActions.A51Title",
    A52Example: "evaluation:predefinedActions.A52Example",
    A52GeneralTip: "evaluation:predefinedActions.A52GeneralTip",
    A52Title: "evaluation:predefinedActions.A52Title",
    A53Example: "evaluation:predefinedActions.A53Example",
    A53GeneralTip: "evaluation:predefinedActions.A53GeneralTip",
    A53Title: "evaluation:predefinedActions.A53Title",
    A54Example: "evaluation:predefinedActions.A54Example",
    A54GeneralTip: "evaluation:predefinedActions.A54GeneralTip",
    A54Title: "evaluation:predefinedActions.A54Title",
    A55Example: "evaluation:predefinedActions.A55Example",
    A55GeneralTip: "evaluation:predefinedActions.A55GeneralTip",
    A55Title: "evaluation:predefinedActions.A55Title",
    A56Example: "evaluation:predefinedActions.A56Example",
    A56GeneralTip: "evaluation:predefinedActions.A56GeneralTip",
    A56Title: "evaluation:predefinedActions.A56Title",
    A57Example: "evaluation:predefinedActions.A57Example",
    A57GeneralTip: "evaluation:predefinedActions.A57GeneralTip",
    A57Title: "evaluation:predefinedActions.A57Title",
    A58Example: "evaluation:predefinedActions.A58Example",
    A58GeneralTip: "evaluation:predefinedActions.A58GeneralTip",
    A58Title: "evaluation:predefinedActions.A58Title",
    A59Example: "evaluation:predefinedActions.A59Example",
    A59GeneralTip: "evaluation:predefinedActions.A59GeneralTip",
    A59Title: "evaluation:predefinedActions.A59Title",
    A5Example: "evaluation:predefinedActions.A5Example",
    A5GeneralTip: "evaluation:predefinedActions.A5GeneralTip",
    A5Title: "evaluation:predefinedActions.A5Title",
    A60Example: "evaluation:predefinedActions.A60Example",
    A60GeneralTip: "evaluation:predefinedActions.A60GeneralTip",
    A60Title: "evaluation:predefinedActions.A60Title",
    A61Example: "evaluation:predefinedActions.A61Example",
    A61GeneralTip: "evaluation:predefinedActions.A61GeneralTip",
    A61Title: "evaluation:predefinedActions.A61Title",
    A62Example: "evaluation:predefinedActions.A62Example",
    A62GeneralTip: "evaluation:predefinedActions.A62GeneralTip",
    A62Title: "evaluation:predefinedActions.A62Title",
    A63Example: "evaluation:predefinedActions.A63Example",
    A63GeneralTip: "evaluation:predefinedActions.A63GeneralTip",
    A63Title: "evaluation:predefinedActions.A63Title",
    A64Example: "evaluation:predefinedActions.A64Example",
    A64GeneralTip: "evaluation:predefinedActions.A64GeneralTip",
    A64Title: "evaluation:predefinedActions.A64Title",
    A65Example: "evaluation:predefinedActions.A65Example",
    A65GeneralTip: "evaluation:predefinedActions.A65GeneralTip",
    A65Title: "evaluation:predefinedActions.A65Title",
    A66Example: "evaluation:predefinedActions.A66Example",
    A66GeneralTip: "evaluation:predefinedActions.A66GeneralTip",
    A66Title: "evaluation:predefinedActions.A66Title",
    A67Example: "evaluation:predefinedActions.A67Example",
    A67GeneralTip: "evaluation:predefinedActions.A67GeneralTip",
    A67Title: "evaluation:predefinedActions.A67Title",
    A68Example: "evaluation:predefinedActions.A68Example",
    A68GeneralTip: "evaluation:predefinedActions.A68GeneralTip",
    A68Title: "evaluation:predefinedActions.A68Title",
    A69Example: "evaluation:predefinedActions.A69Example",
    A69GeneralTip: "evaluation:predefinedActions.A69GeneralTip",
    A69Title: "evaluation:predefinedActions.A69Title",
    A6Example: "evaluation:predefinedActions.A6Example",
    A6GeneralTip: "evaluation:predefinedActions.A6GeneralTip",
    A6Title: "evaluation:predefinedActions.A6Title",
    A70Example: "evaluation:predefinedActions.A70Example",
    A70GeneralTip: "evaluation:predefinedActions.A70GeneralTip",
    A70Title: "evaluation:predefinedActions.A70Title",
    A71Example: "evaluation:predefinedActions.A71Example",
    A71GeneralTip: "evaluation:predefinedActions.A71GeneralTip",
    A71Title: "evaluation:predefinedActions.A71Title",
    A72Example: "evaluation:predefinedActions.A72Example",
    A72GeneralTip: "evaluation:predefinedActions.A72GeneralTip",
    A72Title: "evaluation:predefinedActions.A72Title",
    A73Example: "evaluation:predefinedActions.A73Example",
    A73GeneralTip: "evaluation:predefinedActions.A73GeneralTip",
    A73Title: "evaluation:predefinedActions.A73Title",
    A74Example: "evaluation:predefinedActions.A74Example",
    A74GeneralTip: "evaluation:predefinedActions.A74GeneralTip",
    A74Title: "evaluation:predefinedActions.A74Title",
    A75Example: "evaluation:predefinedActions.A75Example",
    A75GeneralTip: "evaluation:predefinedActions.A75GeneralTip",
    A75Title: "evaluation:predefinedActions.A75Title",
    A76Example: "evaluation:predefinedActions.A76Example",
    A76GeneralTip: "evaluation:predefinedActions.A76GeneralTip",
    A76Title: "evaluation:predefinedActions.A76Title",
    A77Example: "evaluation:predefinedActions.A77Example",
    A77GeneralTip: "evaluation:predefinedActions.A77GeneralTip",
    A77Title: "evaluation:predefinedActions.A77Title",
    A78Example: "evaluation:predefinedActions.A78Example",
    A78GeneralTip: "evaluation:predefinedActions.A78GeneralTip",
    A78Title: "evaluation:predefinedActions.A78Title",
    A79Example: "evaluation:predefinedActions.A79Example",
    A79GeneralTip: "evaluation:predefinedActions.A79GeneralTip",
    A79Title: "evaluation:predefinedActions.A79Title",
    A7Example: "evaluation:predefinedActions.A7Example",
    A7GeneralTip: "evaluation:predefinedActions.A7GeneralTip",
    A7Title: "evaluation:predefinedActions.A7Title",
    A80Example: "evaluation:predefinedActions.A80Example",
    A80GeneralTip: "evaluation:predefinedActions.A80GeneralTip",
    A80Title: "evaluation:predefinedActions.A80Title",
    A81Example: "evaluation:predefinedActions.A81Example",
    A81GeneralTip: "evaluation:predefinedActions.A81GeneralTip",
    A81Title: "evaluation:predefinedActions.A81Title",
    A82Example: "evaluation:predefinedActions.A82Example",
    A82GeneralTip: "evaluation:predefinedActions.A82GeneralTip",
    A82Title: "evaluation:predefinedActions.A82Title",
    A83Example: "evaluation:predefinedActions.A83Example",
    A83GeneralTip: "evaluation:predefinedActions.A83GeneralTip",
    A83Title: "evaluation:predefinedActions.A83Title",
    A84Example: "evaluation:predefinedActions.A84Example",
    A84GeneralTip: "evaluation:predefinedActions.A84GeneralTip",
    A84Title: "evaluation:predefinedActions.A84Title",
    A85Example: "evaluation:predefinedActions.A85Example",
    A85GeneralTip: "evaluation:predefinedActions.A85GeneralTip",
    A85Title: "evaluation:predefinedActions.A85Title",
    A86Example: "evaluation:predefinedActions.A86Example",
    A86GeneralTip: "evaluation:predefinedActions.A86GeneralTip",
    A86Title: "evaluation:predefinedActions.A86Title",
    A87Example: "evaluation:predefinedActions.A87Example",
    A87GeneralTip: "evaluation:predefinedActions.A87GeneralTip",
    A87Title: "evaluation:predefinedActions.A87Title",
    A88Example: "evaluation:predefinedActions.A88Example",
    A88GeneralTip: "evaluation:predefinedActions.A88GeneralTip",
    A88Title: "evaluation:predefinedActions.A88Title",
    A89Example: "evaluation:predefinedActions.A89Example",
    A89GeneralTip: "evaluation:predefinedActions.A89GeneralTip",
    A89Title: "evaluation:predefinedActions.A89Title",
    A8Example: "evaluation:predefinedActions.A8Example",
    A8GeneralTip: "evaluation:predefinedActions.A8GeneralTip",
    A8Title: "evaluation:predefinedActions.A8Title",
    A90Example: "evaluation:predefinedActions.A90Example",
    A90GeneralTip: "evaluation:predefinedActions.A90GeneralTip",
    A90Title: "evaluation:predefinedActions.A90Title",
    A91Example: "evaluation:predefinedActions.A91Example",
    A91GeneralTip: "evaluation:predefinedActions.A91GeneralTip",
    A91Title: "evaluation:predefinedActions.A91Title",
    A92Example: "evaluation:predefinedActions.A92Example",
    A92GeneralTip: "evaluation:predefinedActions.A92GeneralTip",
    A92Title: "evaluation:predefinedActions.A92Title",
    A93Example: "evaluation:predefinedActions.A93Example",
    A93GeneralTip: "evaluation:predefinedActions.A93GeneralTip",
    A93Title: "evaluation:predefinedActions.A93Title",
    A94Example: "evaluation:predefinedActions.A94Example",
    A94GeneralTip: "evaluation:predefinedActions.A94GeneralTip",
    A94Title: "evaluation:predefinedActions.A94Title",
    A95Example: "evaluation:predefinedActions.A95Example",
    A95GeneralTip: "evaluation:predefinedActions.A95GeneralTip",
    A95Title: "evaluation:predefinedActions.A95Title",
    A96Example: "evaluation:predefinedActions.A96Example",
    A96GeneralTip: "evaluation:predefinedActions.A96GeneralTip",
    A96Title: "evaluation:predefinedActions.A96Title",
    A97Example: "evaluation:predefinedActions.A97Example",
    A97GeneralTip: "evaluation:predefinedActions.A97GeneralTip",
    A97Title: "evaluation:predefinedActions.A97Title",
    A98Example: "evaluation:predefinedActions.A98Example",
    A98GeneralTip: "evaluation:predefinedActions.A98GeneralTip",
    A98Title: "evaluation:predefinedActions.A98Title",
    A99Example: "evaluation:predefinedActions.A99Example",
    A99GeneralTip: "evaluation:predefinedActions.A99GeneralTip",
    A99Title: "evaluation:predefinedActions.A99Title",
    A9Example: "evaluation:predefinedActions.A9Example",
    A9GeneralTip: "evaluation:predefinedActions.A9GeneralTip",
    A9Title: "evaluation:predefinedActions.A9Title",
  },
  removeStudentModal: {
    allStudent: {
      confirm: "evaluation:removeStudentModal.allStudent.confirm",
      contentAction: "evaluation:removeStudentModal.allStudent.contentAction",
      contentEvaluation: "evaluation:removeStudentModal.allStudent.contentEvaluation",
      title: "evaluation:removeStudentModal.allStudent.title",
    },
    specificStudent: {
      confirm: "evaluation:removeStudentModal.specificStudent.confirm",
      contentAction: "evaluation:removeStudentModal.specificStudent.contentAction",
      contentEvaluation: "evaluation:removeStudentModal.specificStudent.contentEvaluation",
      title: "evaluation:removeStudentModal.specificStudent.title",
    },
  },
  subaspects: {
    selector: {
      allSubaspects: "evaluation:subaspects.selector.allSubaspects",
      selectSubaspects: "evaluation:subaspects.selector.selectSubaspects",
    },
    subaspect1: "evaluation:subaspects.subaspect1",
    subaspect2: "evaluation:subaspects.subaspect2",
    subaspect3: "evaluation:subaspects.subaspect3",
    subaspect4: "evaluation:subaspects.subaspect4",
    subaspect5: "evaluation:subaspects.subaspect5",
    subaspect6: "evaluation:subaspects.subaspect6",
    subaspect7: "evaluation:subaspects.subaspect7",
    subaspect8: "evaluation:subaspects.subaspect8",
    subaspect9: "evaluation:subaspects.subaspect9",
  },
  targetAudience: {
    multipleStudents: "evaluation:targetAudience.multipleStudents",
    school: "evaluation:targetAudience.school",
    student: "evaluation:targetAudience.student",
  },
  test: "evaluation:test",
  wideViewTools: {
    TableQuestionTypeHeaderQuestion: "evaluation:wideViewTools.TableQuestionTypeHeaderQuestion",
    TableQuestionTypeHeaders: {
      1: "evaluation:wideViewTools.TableQuestionTypeHeaders.1",
      2: "evaluation:wideViewTools.TableQuestionTypeHeaders.2",
      3: "evaluation:wideViewTools.TableQuestionTypeHeaders.3",
      4: "evaluation:wideViewTools.TableQuestionTypeHeaders.4",
    },
    WVT10Description: "evaluation:wideViewTools.WVT10Description",
    WVT10Instruction: "evaluation:wideViewTools.WVT10Instruction",
    WVT10Materials: "evaluation:wideViewTools.WVT10Materials",
    WVT10Q10Title: "evaluation:wideViewTools.WVT10Q10Title",
    WVT10Q11Title: "evaluation:wideViewTools.WVT10Q11Title",
    WVT10Q12Title: "evaluation:wideViewTools.WVT10Q12Title",
    WVT10Q13Title: "evaluation:wideViewTools.WVT10Q13Title",
    WVT10Q14Title: "evaluation:wideViewTools.WVT10Q14Title",
    WVT10Q1AO1: "evaluation:wideViewTools.WVT10Q1AO1",
    WVT10Q1AO2: "evaluation:wideViewTools.WVT10Q1AO2",
    WVT10Q1AO3: "evaluation:wideViewTools.WVT10Q1AO3",
    WVT10Q1AO4: "evaluation:wideViewTools.WVT10Q1AO4",
    WVT10Q1Description: "evaluation:wideViewTools.WVT10Q1Description",
    WVT10Q1Title: "evaluation:wideViewTools.WVT10Q1Title",
    WVT10Q2AO1: "evaluation:wideViewTools.WVT10Q2AO1",
    WVT10Q2AO2: "evaluation:wideViewTools.WVT10Q2AO2",
    WVT10Q2AO3: "evaluation:wideViewTools.WVT10Q2AO3",
    WVT10Q2AO4: "evaluation:wideViewTools.WVT10Q2AO4",
    WVT10Q2Description: "evaluation:wideViewTools.WVT10Q2Description",
    WVT10Q2Title: "evaluation:wideViewTools.WVT10Q2Title",
    WVT10Q3AO1: "evaluation:wideViewTools.WVT10Q3AO1",
    WVT10Q3AO2: "evaluation:wideViewTools.WVT10Q3AO2",
    WVT10Q3AO3: "evaluation:wideViewTools.WVT10Q3AO3",
    WVT10Q3AO4: "evaluation:wideViewTools.WVT10Q3AO4",
    WVT10Q3AO5: "evaluation:wideViewTools.WVT10Q3AO5",
    WVT10Q3AO6: "evaluation:wideViewTools.WVT10Q3AO6",
    WVT10Q3Description: "evaluation:wideViewTools.WVT10Q3Description",
    WVT10Q3Title: "evaluation:wideViewTools.WVT10Q3Title",
    WVT10Q4AO1: "evaluation:wideViewTools.WVT10Q4AO1",
    WVT10Q4AO2: "evaluation:wideViewTools.WVT10Q4AO2",
    WVT10Q4AO3: "evaluation:wideViewTools.WVT10Q4AO3",
    WVT10Q4AO4: "evaluation:wideViewTools.WVT10Q4AO4",
    WVT10Q4Description: "evaluation:wideViewTools.WVT10Q4Description",
    WVT10Q4Title: "evaluation:wideViewTools.WVT10Q4Title",
    WVT10Q5AO1: "evaluation:wideViewTools.WVT10Q5AO1",
    WVT10Q5AO2: "evaluation:wideViewTools.WVT10Q5AO2",
    WVT10Q5AO3: "evaluation:wideViewTools.WVT10Q5AO3",
    WVT10Q5AO4: "evaluation:wideViewTools.WVT10Q5AO4",
    WVT10Q5AO5: "evaluation:wideViewTools.WVT10Q5AO5",
    WVT10Q5Description: "evaluation:wideViewTools.WVT10Q5Description",
    WVT10Q5Title: "evaluation:wideViewTools.WVT10Q5Title",
    WVT10Q6AO1: "evaluation:wideViewTools.WVT10Q6AO1",
    WVT10Q6AO2: "evaluation:wideViewTools.WVT10Q6AO2",
    WVT10Q6AO3: "evaluation:wideViewTools.WVT10Q6AO3",
    WVT10Q6AO4: "evaluation:wideViewTools.WVT10Q6AO4",
    WVT10Q6Title: "evaluation:wideViewTools.WVT10Q6Title",
    WVT10Q7Title: "evaluation:wideViewTools.WVT10Q7Title",
    WVT10Q8Description: "evaluation:wideViewTools.WVT10Q8Description",
    WVT10Q8Title: "evaluation:wideViewTools.WVT10Q8Title",
    WVT10Q9Title: "evaluation:wideViewTools.WVT10Q9Title",
    WVT10Reason: "evaluation:wideViewTools.WVT10Reason",
    WVT10Title: "evaluation:wideViewTools.WVT10Title",
    WVT11Description: "evaluation:wideViewTools.WVT11Description",
    WVT11Instruction: "evaluation:wideViewTools.WVT11Instruction",
    WVT11Materials: "evaluation:wideViewTools.WVT11Materials",
    WVT11Q10Description: "evaluation:wideViewTools.WVT11Q10Description",
    WVT11Q10Title: "evaluation:wideViewTools.WVT11Q10Title",
    WVT11Q11Description: "evaluation:wideViewTools.WVT11Q11Description",
    WVT11Q11Title: "evaluation:wideViewTools.WVT11Q11Title",
    WVT11Q12Description: "evaluation:wideViewTools.WVT11Q12Description",
    WVT11Q12Title: "evaluation:wideViewTools.WVT11Q12Title",
    WVT11Q13Title: "evaluation:wideViewTools.WVT11Q13Title",
    WVT11Q1Description: "evaluation:wideViewTools.WVT11Q1Description",
    WVT11Q1Title: "evaluation:wideViewTools.WVT11Q1Title",
    WVT11Q2Description: "evaluation:wideViewTools.WVT11Q2Description",
    WVT11Q2Title: "evaluation:wideViewTools.WVT11Q2Title",
    WVT11Q3Description: "evaluation:wideViewTools.WVT11Q3Description",
    WVT11Q3Title: "evaluation:wideViewTools.WVT11Q3Title",
    WVT11Q4Description: "evaluation:wideViewTools.WVT11Q4Description",
    WVT11Q4Title: "evaluation:wideViewTools.WVT11Q4Title",
    WVT11Q5Description: "evaluation:wideViewTools.WVT11Q5Description",
    WVT11Q5Title: "evaluation:wideViewTools.WVT11Q5Title",
    WVT11Q6Description: "evaluation:wideViewTools.WVT11Q6Description",
    WVT11Q6Title: "evaluation:wideViewTools.WVT11Q6Title",
    WVT11Q7Description: "evaluation:wideViewTools.WVT11Q7Description",
    WVT11Q7Title: "evaluation:wideViewTools.WVT11Q7Title",
    WVT11Q8Description: "evaluation:wideViewTools.WVT11Q8Description",
    WVT11Q8Title: "evaluation:wideViewTools.WVT11Q8Title",
    WVT11Q9Description: "evaluation:wideViewTools.WVT11Q9Description",
    WVT11Q9Title: "evaluation:wideViewTools.WVT11Q9Title",
    WVT11Reason: "evaluation:wideViewTools.WVT11Reason",
    WVT11Title: "evaluation:wideViewTools.WVT11Title",
    WVT12Description: "evaluation:wideViewTools.WVT12Description",
    WVT12Instruction: "evaluation:wideViewTools.WVT12Instruction",
    WVT12Materials: "evaluation:wideViewTools.WVT12Materials",
    WVT12Q1AO1: "evaluation:wideViewTools.WVT12Q1AO1",
    WVT12Q1AO10: "evaluation:wideViewTools.WVT12Q1AO10",
    WVT12Q1AO11: "evaluation:wideViewTools.WVT12Q1AO11",
    WVT12Q1AO12: "evaluation:wideViewTools.WVT12Q1AO12",
    WVT12Q1AO13: "evaluation:wideViewTools.WVT12Q1AO13",
    WVT12Q1AO14: "evaluation:wideViewTools.WVT12Q1AO14",
    WVT12Q1AO15: "evaluation:wideViewTools.WVT12Q1AO15",
    WVT12Q1AO16: "evaluation:wideViewTools.WVT12Q1AO16",
    WVT12Q1AO17: "evaluation:wideViewTools.WVT12Q1AO17",
    WVT12Q1AO18: "evaluation:wideViewTools.WVT12Q1AO18",
    WVT12Q1AO19: "evaluation:wideViewTools.WVT12Q1AO19",
    WVT12Q1AO2: "evaluation:wideViewTools.WVT12Q1AO2",
    WVT12Q1AO20: "evaluation:wideViewTools.WVT12Q1AO20",
    WVT12Q1AO21: "evaluation:wideViewTools.WVT12Q1AO21",
    WVT12Q1AO22: "evaluation:wideViewTools.WVT12Q1AO22",
    WVT12Q1AO23: "evaluation:wideViewTools.WVT12Q1AO23",
    WVT12Q1AO3: "evaluation:wideViewTools.WVT12Q1AO3",
    WVT12Q1AO4: "evaluation:wideViewTools.WVT12Q1AO4",
    WVT12Q1AO5: "evaluation:wideViewTools.WVT12Q1AO5",
    WVT12Q1AO6: "evaluation:wideViewTools.WVT12Q1AO6",
    WVT12Q1AO7: "evaluation:wideViewTools.WVT12Q1AO7",
    WVT12Q1AO8: "evaluation:wideViewTools.WVT12Q1AO8",
    WVT12Q1AO9: "evaluation:wideViewTools.WVT12Q1AO9",
    WVT12Q1Title: "evaluation:wideViewTools.WVT12Q1Title",
    WVT12Q2Title: "evaluation:wideViewTools.WVT12Q2Title",
    WVT12Reason: "evaluation:wideViewTools.WVT12Reason",
    WVT12Title: "evaluation:wideViewTools.WVT12Title",
    WVT13Description: "evaluation:wideViewTools.WVT13Description",
    WVT13Instruction: "evaluation:wideViewTools.WVT13Instruction",
    WVT13Materials: "evaluation:wideViewTools.WVT13Materials",
    WVT13Q1AO1: "evaluation:wideViewTools.WVT13Q1AO1",
    WVT13Q1AO2: "evaluation:wideViewTools.WVT13Q1AO2",
    WVT13Q1AO3: "evaluation:wideViewTools.WVT13Q1AO3",
    WVT13Q1AO4: "evaluation:wideViewTools.WVT13Q1AO4",
    WVT13Q1Description: "evaluation:wideViewTools.WVT13Q1Description",
    WVT13Q1Title: "evaluation:wideViewTools.WVT13Q1Title",
    WVT13Q2AO1: "evaluation:wideViewTools.WVT13Q2AO1",
    WVT13Q2AO10: "evaluation:wideViewTools.WVT13Q2AO10",
    WVT13Q2AO11: "evaluation:wideViewTools.WVT13Q2AO11",
    WVT13Q2AO12: "evaluation:wideViewTools.WVT13Q2AO12",
    WVT13Q2AO13: "evaluation:wideViewTools.WVT13Q2AO13",
    WVT13Q2AO14: "evaluation:wideViewTools.WVT13Q2AO14",
    WVT13Q2AO15: "evaluation:wideViewTools.WVT13Q2AO15",
    WVT13Q2AO16: "evaluation:wideViewTools.WVT13Q2AO16",
    WVT13Q2AO17: "evaluation:wideViewTools.WVT13Q2AO17",
    WVT13Q2AO18: "evaluation:wideViewTools.WVT13Q2AO18",
    WVT13Q2AO19: "evaluation:wideViewTools.WVT13Q2AO19",
    WVT13Q2AO2: "evaluation:wideViewTools.WVT13Q2AO2",
    WVT13Q2AO20: "evaluation:wideViewTools.WVT13Q2AO20",
    WVT13Q2AO21: "evaluation:wideViewTools.WVT13Q2AO21",
    WVT13Q2AO22: "evaluation:wideViewTools.WVT13Q2AO22",
    WVT13Q2AO23: "evaluation:wideViewTools.WVT13Q2AO23",
    WVT13Q2AO24: "evaluation:wideViewTools.WVT13Q2AO24",
    WVT13Q2AO25: "evaluation:wideViewTools.WVT13Q2AO25",
    WVT13Q2AO3: "evaluation:wideViewTools.WVT13Q2AO3",
    WVT13Q2AO4: "evaluation:wideViewTools.WVT13Q2AO4",
    WVT13Q2AO5: "evaluation:wideViewTools.WVT13Q2AO5",
    WVT13Q2AO6: "evaluation:wideViewTools.WVT13Q2AO6",
    WVT13Q2AO7: "evaluation:wideViewTools.WVT13Q2AO7",
    WVT13Q2AO8: "evaluation:wideViewTools.WVT13Q2AO8",
    WVT13Q2AO9: "evaluation:wideViewTools.WVT13Q2AO9",
    WVT13Q2Description: "evaluation:wideViewTools.WVT13Q2Description",
    WVT13Q2Title: "evaluation:wideViewTools.WVT13Q2Title",
    WVT13Q3AO1: "evaluation:wideViewTools.WVT13Q3AO1",
    WVT13Q3AO2: "evaluation:wideViewTools.WVT13Q3AO2",
    WVT13Q3AO3: "evaluation:wideViewTools.WVT13Q3AO3",
    WVT13Q3AO4: "evaluation:wideViewTools.WVT13Q3AO4",
    WVT13Q3Description: "evaluation:wideViewTools.WVT13Q3Description",
    WVT13Q3Title: "evaluation:wideViewTools.WVT13Q3Title",
    WVT13Q4Title: "evaluation:wideViewTools.WVT13Q4Title",
    WVT13Reason: "evaluation:wideViewTools.WVT13Reason",
    WVT13Title: "evaluation:wideViewTools.WVT13Title",
    WVT14Description: "evaluation:wideViewTools.WVT14Description",
    WVT14Instruction: "evaluation:wideViewTools.WVT14Instruction",
    WVT14Materials: "evaluation:wideViewTools.WVT14Materials",
    WVT14Q0Description: "evaluation:wideViewTools.WVT14Q0Description",
    WVT14Q0Title: "evaluation:wideViewTools.WVT14Q0Title",
    WVT14Q10AO1: "evaluation:wideViewTools.WVT14Q10AO1",
    WVT14Q10AO2: "evaluation:wideViewTools.WVT14Q10AO2",
    WVT14Q10Description: "evaluation:wideViewTools.WVT14Q10Description",
    WVT14Q11AO1: "evaluation:wideViewTools.WVT14Q11AO1",
    WVT14Q11AO2: "evaluation:wideViewTools.WVT14Q11AO2",
    WVT14Q11AO3: "evaluation:wideViewTools.WVT14Q11AO3",
    WVT14Q11AO4: "evaluation:wideViewTools.WVT14Q11AO4",
    WVT14Q11Description: "evaluation:wideViewTools.WVT14Q11Description",
    WVT14Q12AO1: "evaluation:wideViewTools.WVT14Q12AO1",
    WVT14Q12AO2: "evaluation:wideViewTools.WVT14Q12AO2",
    WVT14Q12AO3: "evaluation:wideViewTools.WVT14Q12AO3",
    WVT14Q12AO4: "evaluation:wideViewTools.WVT14Q12AO4",
    WVT14Q12Description: "evaluation:wideViewTools.WVT14Q12Description",
    WVT14Q13AO1: "evaluation:wideViewTools.WVT14Q13AO1",
    WVT14Q13AO2: "evaluation:wideViewTools.WVT14Q13AO2",
    WVT14Q13AO3: "evaluation:wideViewTools.WVT14Q13AO3",
    WVT14Q13Description: "evaluation:wideViewTools.WVT14Q13Description",
    WVT14Q14Description: "evaluation:wideViewTools.WVT14Q14Description",
    WVT14Q14Title: "evaluation:wideViewTools.WVT14Q14Title",
    WVT14Q15Title: "evaluation:wideViewTools.WVT14Q15Title",
    WVT14Q16Title: "evaluation:wideViewTools.WVT14Q16Title",
    WVT14Q17Title: "evaluation:wideViewTools.WVT14Q17Title",
    WVT14Q18Title: "evaluation:wideViewTools.WVT14Q18Title",
    WVT14Q19Title: "evaluation:wideViewTools.WVT14Q19Title",
    WVT14Q1AO1: "evaluation:wideViewTools.WVT14Q1AO1",
    WVT14Q1AO2: "evaluation:wideViewTools.WVT14Q1AO2",
    WVT14Q1AO3: "evaluation:wideViewTools.WVT14Q1AO3",
    WVT14Q1AO4: "evaluation:wideViewTools.WVT14Q1AO4",
    WVT14Q1AO5: "evaluation:wideViewTools.WVT14Q1AO5",
    WVT14Q1Title: "evaluation:wideViewTools.WVT14Q1Title",
    WVT14Q20Title: "evaluation:wideViewTools.WVT14Q20Title",
    WVT14Q21Title: "evaluation:wideViewTools.WVT14Q21Title",
    WVT14Q22Title: "evaluation:wideViewTools.WVT14Q22Title",
    WVT14Q23Description: "evaluation:wideViewTools.WVT14Q23Description",
    WVT14Q24Title: "evaluation:wideViewTools.WVT14Q24Title",
    WVT14Q25Description: "evaluation:wideViewTools.WVT14Q25Description",
    WVT14Q25Title: "evaluation:wideViewTools.WVT14Q25Title",
    WVT14Q26Description: "evaluation:wideViewTools.WVT14Q26Description",
    WVT14Q27Description: "evaluation:wideViewTools.WVT14Q27Description",
    WVT14Q28Description: "evaluation:wideViewTools.WVT14Q28Description",
    WVT14Q29Description: "evaluation:wideViewTools.WVT14Q29Description",
    WVT14Q30Description: "evaluation:wideViewTools.WVT14Q30Description",
    WVT14Q3Title: "evaluation:wideViewTools.WVT14Q3Title",
    WVT14Q4AO1: "evaluation:wideViewTools.WVT14Q4AO1",
    WVT14Q4AO2: "evaluation:wideViewTools.WVT14Q4AO2",
    WVT14Q4AO3: "evaluation:wideViewTools.WVT14Q4AO3",
    WVT14Q4AO4: "evaluation:wideViewTools.WVT14Q4AO4",
    WVT14Q4AO5: "evaluation:wideViewTools.WVT14Q4AO5",
    WVT14Q4Description: "evaluation:wideViewTools.WVT14Q4Description",
    WVT14Q4Title: "evaluation:wideViewTools.WVT14Q4Title",
    WVT14Q5AO1: "evaluation:wideViewTools.WVT14Q5AO1",
    WVT14Q5AO2: "evaluation:wideViewTools.WVT14Q5AO2",
    WVT14Q5AO3: "evaluation:wideViewTools.WVT14Q5AO3",
    WVT14Q5AO4: "evaluation:wideViewTools.WVT14Q5AO4",
    WVT14Q5Description: "evaluation:wideViewTools.WVT14Q5Description",
    WVT14Q6AO1: "evaluation:wideViewTools.WVT14Q6AO1",
    WVT14Q6AO2: "evaluation:wideViewTools.WVT14Q6AO2",
    WVT14Q6Description: "evaluation:wideViewTools.WVT14Q6Description",
    WVT14Q7AO1: "evaluation:wideViewTools.WVT14Q7AO1",
    WVT14Q7AO2: "evaluation:wideViewTools.WVT14Q7AO2",
    WVT14Q7AO3: "evaluation:wideViewTools.WVT14Q7AO3",
    WVT14Q7AO4: "evaluation:wideViewTools.WVT14Q7AO4",
    WVT14Q7AO5: "evaluation:wideViewTools.WVT14Q7AO5",
    WVT14Q7AO6: "evaluation:wideViewTools.WVT14Q7AO6",
    WVT14Q7Description: "evaluation:wideViewTools.WVT14Q7Description",
    WVT14Q8AO1: "evaluation:wideViewTools.WVT14Q8AO1",
    WVT14Q8AO2: "evaluation:wideViewTools.WVT14Q8AO2",
    WVT14Q8AO3: "evaluation:wideViewTools.WVT14Q8AO3",
    WVT14Q8Description: "evaluation:wideViewTools.WVT14Q8Description",
    WVT14Q9AO1: "evaluation:wideViewTools.WVT14Q9AO1",
    WVT14Q9AO2: "evaluation:wideViewTools.WVT14Q9AO2",
    WVT14Q9AO3: "evaluation:wideViewTools.WVT14Q9AO3",
    WVT14Q9AO4: "evaluation:wideViewTools.WVT14Q9AO4",
    WVT14Q9AO5: "evaluation:wideViewTools.WVT14Q9AO5",
    WVT14Q9Description: "evaluation:wideViewTools.WVT14Q9Description",
    WVT14Reason: "evaluation:wideViewTools.WVT14Reason",
    WVT14Title: "evaluation:wideViewTools.WVT14Title",
    WVT1Q10AO1: "evaluation:wideViewTools.WVT1Q10AO1",
    WVT1Q10AO2: "evaluation:wideViewTools.WVT1Q10AO2",
    WVT1Q10AO3: "evaluation:wideViewTools.WVT1Q10AO3",
    WVT1Q10AO4: "evaluation:wideViewTools.WVT1Q10AO4",
    WVT1Q10AO5: "evaluation:wideViewTools.WVT1Q10AO5",
    WVT1Q10AO6: "evaluation:wideViewTools.WVT1Q10AO6",
    WVT1Q11AO1: "evaluation:wideViewTools.WVT1Q11AO1",
    WVT1Q11AO2: "evaluation:wideViewTools.WVT1Q11AO2",
    WVT1Q11AO3: "evaluation:wideViewTools.WVT1Q11AO3",
    WVT1Q11AO4: "evaluation:wideViewTools.WVT1Q11AO4",
    WVT1Q11AO5: "evaluation:wideViewTools.WVT1Q11AO5",
    WVT1Q11AO6: "evaluation:wideViewTools.WVT1Q11AO6",
    WVT1Q11Title: "evaluation:wideViewTools.WVT1Q11Title",
    WVT1Q1AO1: "evaluation:wideViewTools.WVT1Q1AO1",
    WVT1Q1AO10: "evaluation:wideViewTools.WVT1Q1AO10",
    WVT1Q1AO11: "evaluation:wideViewTools.WVT1Q1AO11",
    WVT1Q1AO12: "evaluation:wideViewTools.WVT1Q1AO12",
    WVT1Q1AO13: "evaluation:wideViewTools.WVT1Q1AO13",
    WVT1Q1AO14: "evaluation:wideViewTools.WVT1Q1AO14",
    WVT1Q1AO15: "evaluation:wideViewTools.WVT1Q1AO15",
    WVT1Q1AO16: "evaluation:wideViewTools.WVT1Q1AO16",
    WVT1Q1AO17: "evaluation:wideViewTools.WVT1Q1AO17",
    WVT1Q1AO18: "evaluation:wideViewTools.WVT1Q1AO18",
    WVT1Q1AO19: "evaluation:wideViewTools.WVT1Q1AO19",
    WVT1Q1AO1DESC: "evaluation:wideViewTools.WVT1Q1AO1DESC",
    WVT1Q1AO2: "evaluation:wideViewTools.WVT1Q1AO2",
    WVT1Q1AO3: "evaluation:wideViewTools.WVT1Q1AO3",
    WVT1Q1AO4: "evaluation:wideViewTools.WVT1Q1AO4",
    WVT1Q1AO5: "evaluation:wideViewTools.WVT1Q1AO5",
    WVT1Q1AO5DESC: "evaluation:wideViewTools.WVT1Q1AO5DESC",
    WVT1Q1AO6: "evaluation:wideViewTools.WVT1Q1AO6",
    WVT1Q1AO7: "evaluation:wideViewTools.WVT1Q1AO7",
    WVT1Q1AO8: "evaluation:wideViewTools.WVT1Q1AO8",
    WVT1Q1AO9: "evaluation:wideViewTools.WVT1Q1AO9",
    WVT1Q1Title: "evaluation:wideViewTools.WVT1Q1Title",
    WVT1Q2AO1: "evaluation:wideViewTools.WVT1Q2AO1",
    WVT1Q2AO2: "evaluation:wideViewTools.WVT1Q2AO2",
    WVT1Q3AO1: "evaluation:wideViewTools.WVT1Q3AO1",
    WVT1Q3AO2: "evaluation:wideViewTools.WVT1Q3AO2",
    WVT1Q3AO3: "evaluation:wideViewTools.WVT1Q3AO3",
    WVT1Q4AO1: "evaluation:wideViewTools.WVT1Q4AO1",
    WVT1Q4AO2: "evaluation:wideViewTools.WVT1Q4AO2",
    WVT1Q4AO3: "evaluation:wideViewTools.WVT1Q4AO3",
    WVT1Q4AO4: "evaluation:wideViewTools.WVT1Q4AO4",
    WVT1Q4AO5: "evaluation:wideViewTools.WVT1Q4AO5",
    WVT1Q5AO1: "evaluation:wideViewTools.WVT1Q5AO1",
    WVT1Q5AO2: "evaluation:wideViewTools.WVT1Q5AO2",
    WVT1Q5AO3: "evaluation:wideViewTools.WVT1Q5AO3",
    WVT1Q6AO1: "evaluation:wideViewTools.WVT1Q6AO1",
    WVT1Q6AO10: "evaluation:wideViewTools.WVT1Q6AO10",
    WVT1Q6AO2: "evaluation:wideViewTools.WVT1Q6AO2",
    WVT1Q6AO3: "evaluation:wideViewTools.WVT1Q6AO3",
    WVT1Q6AO4: "evaluation:wideViewTools.WVT1Q6AO4",
    WVT1Q6AO5: "evaluation:wideViewTools.WVT1Q6AO5",
    WVT1Q6AO6: "evaluation:wideViewTools.WVT1Q6AO6",
    WVT1Q6AO7: "evaluation:wideViewTools.WVT1Q6AO7",
    WVT1Q6AO8: "evaluation:wideViewTools.WVT1Q6AO8",
    WVT1Q6AO9: "evaluation:wideViewTools.WVT1Q6AO9",
    WVT1Q7AO1: "evaluation:wideViewTools.WVT1Q7AO1",
    WVT1Q7AO2: "evaluation:wideViewTools.WVT1Q7AO2",
    WVT1Q7AO3: "evaluation:wideViewTools.WVT1Q7AO3",
    WVT1Q7AO4: "evaluation:wideViewTools.WVT1Q7AO4",
    WVT1Q7AO5: "evaluation:wideViewTools.WVT1Q7AO5",
    WVT1Q8AO1: "evaluation:wideViewTools.WVT1Q8AO1",
    WVT1Q8AO2: "evaluation:wideViewTools.WVT1Q8AO2",
    WVT1Q8AO3: "evaluation:wideViewTools.WVT1Q8AO3",
    WVT1Q9AO1: "evaluation:wideViewTools.WVT1Q9AO1",
    WVT1Q9AO2: "evaluation:wideViewTools.WVT1Q9AO2",
    WVT1Q9AO3: "evaluation:wideViewTools.WVT1Q9AO3",
    WVT2Q1AO1: "evaluation:wideViewTools.WVT2Q1AO1",
    WVT2Q1AO2: "evaluation:wideViewTools.WVT2Q1AO2",
    WVT2Q1AO3: "evaluation:wideViewTools.WVT2Q1AO3",
    WVT2Q1Title: "evaluation:wideViewTools.WVT2Q1Title",
    WVT2Q2Description: "evaluation:wideViewTools.WVT2Q2Description",
    WVT2Q2Title: "evaluation:wideViewTools.WVT2Q2Title",
    WVT2Q3Description: "evaluation:wideViewTools.WVT2Q3Description",
    WVT2Q3Title: "evaluation:wideViewTools.WVT2Q3Title",
    WVT2Q4Description: "evaluation:wideViewTools.WVT2Q4Description",
    WVT2Q4Title: "evaluation:wideViewTools.WVT2Q4Title",
    WVT2Q5Description: "evaluation:wideViewTools.WVT2Q5Description",
    WVT2Q5Title: "evaluation:wideViewTools.WVT2Q5Title",
    WVT2Q6Description: "evaluation:wideViewTools.WVT2Q6Description",
    WVT2Q6Title: "evaluation:wideViewTools.WVT2Q6Title",
    WVT2Q7Title: "evaluation:wideViewTools.WVT2Q7Title",
    WVT3Q10Title: "evaluation:wideViewTools.WVT3Q10Title",
    WVT3Q11Title: "evaluation:wideViewTools.WVT3Q11Title",
    WVT3Q12Title: "evaluation:wideViewTools.WVT3Q12Title",
    WVT3Q13Title: "evaluation:wideViewTools.WVT3Q13Title",
    WVT3Q14Title: "evaluation:wideViewTools.WVT3Q14Title",
    WVT3Q15Title: "evaluation:wideViewTools.WVT3Q15Title",
    WVT3Q16Title: "evaluation:wideViewTools.WVT3Q16Title",
    WVT3Q17Title: "evaluation:wideViewTools.WVT3Q17Title",
    WVT3Q18Title: "evaluation:wideViewTools.WVT3Q18Title",
    WVT3Q19Title: "evaluation:wideViewTools.WVT3Q19Title",
    WVT3Q1AO1: "evaluation:wideViewTools.WVT3Q1AO1",
    WVT3Q1AO2: "evaluation:wideViewTools.WVT3Q1AO2",
    WVT3Q1AO3: "evaluation:wideViewTools.WVT3Q1AO3",
    WVT3Q1AO4: "evaluation:wideViewTools.WVT3Q1AO4",
    WVT3Q1AO5: "evaluation:wideViewTools.WVT3Q1AO5",
    WVT3Q1Description: "evaluation:wideViewTools.WVT3Q1Description",
    WVT3Q1Title: "evaluation:wideViewTools.WVT3Q1Title",
    WVT3Q20Title: "evaluation:wideViewTools.WVT3Q20Title",
    WVT3Q21AO1: "evaluation:wideViewTools.WVT3Q21AO1",
    WVT3Q21AO2: "evaluation:wideViewTools.WVT3Q21AO2",
    WVT3Q21AO3: "evaluation:wideViewTools.WVT3Q21AO3",
    WVT3Q21AO4: "evaluation:wideViewTools.WVT3Q21AO4",
    WVT3Q21AO5: "evaluation:wideViewTools.WVT3Q21AO5",
    WVT3Q21AO6: "evaluation:wideViewTools.WVT3Q21AO6",
    WVT3Q21Title: "evaluation:wideViewTools.WVT3Q21Title",
    WVT3Q22AO1: "evaluation:wideViewTools.WVT3Q22AO1",
    WVT3Q22AO2: "evaluation:wideViewTools.WVT3Q22AO2",
    WVT3Q22AO3: "evaluation:wideViewTools.WVT3Q22AO3",
    WVT3Q22AO4: "evaluation:wideViewTools.WVT3Q22AO4",
    WVT3Q22AO5: "evaluation:wideViewTools.WVT3Q22AO5",
    WVT3Q22AO6: "evaluation:wideViewTools.WVT3Q22AO6",
    WVT3Q22Title: "evaluation:wideViewTools.WVT3Q22Title",
    WVT3Q23Title: "evaluation:wideViewTools.WVT3Q23Title",
    WVT3Q24Title: "evaluation:wideViewTools.WVT3Q24Title",
    WVT3Q2AO1: "evaluation:wideViewTools.WVT3Q2AO1",
    WVT3Q2AO2: "evaluation:wideViewTools.WVT3Q2AO2",
    WVT3Q2AO3: "evaluation:wideViewTools.WVT3Q2AO3",
    WVT3Q2AO4: "evaluation:wideViewTools.WVT3Q2AO4",
    WVT3Q2AO5: "evaluation:wideViewTools.WVT3Q2AO5",
    WVT3Q2AO6: "evaluation:wideViewTools.WVT3Q2AO6",
    WVT3Q2AO7: "evaluation:wideViewTools.WVT3Q2AO7",
    WVT3Q2Title: "evaluation:wideViewTools.WVT3Q2Title",
    WVT3Q3AO1: "evaluation:wideViewTools.WVT3Q3AO1",
    WVT3Q3Q3AO1: "evaluation:wideViewTools.WVT3Q3Q3AO1",
    WVT3Q4HeaderText: "evaluation:wideViewTools.WVT3Q4HeaderText",
    WVT3Q4Title: "evaluation:wideViewTools.WVT3Q4Title",
    WVT3Q5Title: "evaluation:wideViewTools.WVT3Q5Title",
    WVT3Q6Title: "evaluation:wideViewTools.WVT3Q6Title",
    WVT3Q7Title: "evaluation:wideViewTools.WVT3Q7Title",
    WVT3Q8Title: "evaluation:wideViewTools.WVT3Q8Title",
    WVT3Q9Title: "evaluation:wideViewTools.WVT3Q9Title",
    WVT4Q10AO1: "evaluation:wideViewTools.WVT4Q10AO1",
    WVT4Q10AO2: "evaluation:wideViewTools.WVT4Q10AO2",
    WVT4Q10AO3: "evaluation:wideViewTools.WVT4Q10AO3",
    WVT4Q10AO4: "evaluation:wideViewTools.WVT4Q10AO4",
    WVT4Q10Title: "evaluation:wideViewTools.WVT4Q10Title",
    WVT4Q11Title: "evaluation:wideViewTools.WVT4Q11Title",
    WVT4Q1AO1: "evaluation:wideViewTools.WVT4Q1AO1",
    WVT4Q1AO2: "evaluation:wideViewTools.WVT4Q1AO2",
    WVT4Q1AO3: "evaluation:wideViewTools.WVT4Q1AO3",
    WVT4Q1Title: "evaluation:wideViewTools.WVT4Q1Title",
    WVT4Q2AO1: "evaluation:wideViewTools.WVT4Q2AO1",
    WVT4Q2AO2: "evaluation:wideViewTools.WVT4Q2AO2",
    WVT4Q2AO3: "evaluation:wideViewTools.WVT4Q2AO3",
    WVT4Q2AO4: "evaluation:wideViewTools.WVT4Q2AO4",
    WVT4Q2Title: "evaluation:wideViewTools.WVT4Q2Title",
    WVT4Q3AO1: "evaluation:wideViewTools.WVT4Q3AO1",
    WVT4Q3AO2: "evaluation:wideViewTools.WVT4Q3AO2",
    WVT4Q3AO3: "evaluation:wideViewTools.WVT4Q3AO3",
    WVT4Q3AO4: "evaluation:wideViewTools.WVT4Q3AO4",
    WVT4Q3Title: "evaluation:wideViewTools.WVT4Q3Title",
    WVT4Q4AO1: "evaluation:wideViewTools.WVT4Q4AO1",
    WVT4Q4AO2: "evaluation:wideViewTools.WVT4Q4AO2",
    WVT4Q4AO3: "evaluation:wideViewTools.WVT4Q4AO3",
    WVT4Q4AO4: "evaluation:wideViewTools.WVT4Q4AO4",
    WVT4Q4Title: "evaluation:wideViewTools.WVT4Q4Title",
    WVT4Q5AO1: "evaluation:wideViewTools.WVT4Q5AO1",
    WVT4Q5AO2: "evaluation:wideViewTools.WVT4Q5AO2",
    WVT4Q5AO3: "evaluation:wideViewTools.WVT4Q5AO3",
    WVT4Q5AO4: "evaluation:wideViewTools.WVT4Q5AO4",
    WVT4Q5Title: "evaluation:wideViewTools.WVT4Q5Title",
    WVT4Q6AO1: "evaluation:wideViewTools.WVT4Q6AO1",
    WVT4Q6AO2: "evaluation:wideViewTools.WVT4Q6AO2",
    WVT4Q6AO3: "evaluation:wideViewTools.WVT4Q6AO3",
    WVT4Q6AO4: "evaluation:wideViewTools.WVT4Q6AO4",
    WVT4Q6Title: "evaluation:wideViewTools.WVT4Q6Title",
    WVT4Q7AO1: "evaluation:wideViewTools.WVT4Q7AO1",
    WVT4Q7AO2: "evaluation:wideViewTools.WVT4Q7AO2",
    WVT4Q7AO3: "evaluation:wideViewTools.WVT4Q7AO3",
    WVT4Q7AO4: "evaluation:wideViewTools.WVT4Q7AO4",
    WVT4Q7AO5: "evaluation:wideViewTools.WVT4Q7AO5",
    WVT4Q7Title: "evaluation:wideViewTools.WVT4Q7Title",
    WVT4Q8AO1: "evaluation:wideViewTools.WVT4Q8AO1",
    WVT4Q8AO2: "evaluation:wideViewTools.WVT4Q8AO2",
    WVT4Q8AO3: "evaluation:wideViewTools.WVT4Q8AO3",
    WVT4Q8AO4: "evaluation:wideViewTools.WVT4Q8AO4",
    WVT4Q8Title: "evaluation:wideViewTools.WVT4Q8Title",
    WVT4Q9AO1: "evaluation:wideViewTools.WVT4Q9AO1",
    WVT4Q9AO2: "evaluation:wideViewTools.WVT4Q9AO2",
    WVT4Q9Title: "evaluation:wideViewTools.WVT4Q9Title",
    WVT5Q1Description: "evaluation:wideViewTools.WVT5Q1Description",
    WVT5Q1Title: "evaluation:wideViewTools.WVT5Q1Title",
    WVT5Q2AO1: "evaluation:wideViewTools.WVT5Q2AO1",
    WVT5Q2AO10: "evaluation:wideViewTools.WVT5Q2AO10",
    WVT5Q2AO11: "evaluation:wideViewTools.WVT5Q2AO11",
    WVT5Q2AO12: "evaluation:wideViewTools.WVT5Q2AO12",
    WVT5Q2AO13: "evaluation:wideViewTools.WVT5Q2AO13",
    WVT5Q2AO13DESC: "evaluation:wideViewTools.WVT5Q2AO13DESC",
    WVT5Q2AO14: "evaluation:wideViewTools.WVT5Q2AO14",
    WVT5Q2AO15: "evaluation:wideViewTools.WVT5Q2AO15",
    WVT5Q2AO16: "evaluation:wideViewTools.WVT5Q2AO16",
    WVT5Q2AO17: "evaluation:wideViewTools.WVT5Q2AO17",
    WVT5Q2AO18: "evaluation:wideViewTools.WVT5Q2AO18",
    WVT5Q2AO19: "evaluation:wideViewTools.WVT5Q2AO19",
    WVT5Q2AO1DESC: "evaluation:wideViewTools.WVT5Q2AO1DESC",
    WVT5Q2AO2: "evaluation:wideViewTools.WVT5Q2AO2",
    WVT5Q2AO20: "evaluation:wideViewTools.WVT5Q2AO20",
    WVT5Q2AO21: "evaluation:wideViewTools.WVT5Q2AO21",
    WVT5Q2AO21DESC: "evaluation:wideViewTools.WVT5Q2AO21DESC",
    WVT5Q2AO22: "evaluation:wideViewTools.WVT5Q2AO22",
    WVT5Q2AO23: "evaluation:wideViewTools.WVT5Q2AO23",
    WVT5Q2AO24: "evaluation:wideViewTools.WVT5Q2AO24",
    WVT5Q2AO25: "evaluation:wideViewTools.WVT5Q2AO25",
    WVT5Q2AO26: "evaluation:wideViewTools.WVT5Q2AO26",
    WVT5Q2AO3: "evaluation:wideViewTools.WVT5Q2AO3",
    WVT5Q2AO4: "evaluation:wideViewTools.WVT5Q2AO4",
    WVT5Q2AO4DESC: "evaluation:wideViewTools.WVT5Q2AO4DESC",
    WVT5Q2AO5: "evaluation:wideViewTools.WVT5Q2AO5",
    WVT5Q2AO6: "evaluation:wideViewTools.WVT5Q2AO6",
    WVT5Q2AO7: "evaluation:wideViewTools.WVT5Q2AO7",
    WVT5Q2AO7DESC: "evaluation:wideViewTools.WVT5Q2AO7DESC",
    WVT5Q2AO8: "evaluation:wideViewTools.WVT5Q2AO8",
    WVT5Q2AO9: "evaluation:wideViewTools.WVT5Q2AO9",
    WVT5Q2AO9DESC: "evaluation:wideViewTools.WVT5Q2AO9DESC",
    WVT5Q2Title: "evaluation:wideViewTools.WVT5Q2Title",
    WVT5Q3AO1: "evaluation:wideViewTools.WVT5Q3AO1",
    WVT5Q3AO10: "evaluation:wideViewTools.WVT5Q3AO10",
    WVT5Q3AO11: "evaluation:wideViewTools.WVT5Q3AO11",
    WVT5Q3AO12: "evaluation:wideViewTools.WVT5Q3AO12",
    WVT5Q3AO13: "evaluation:wideViewTools.WVT5Q3AO13",
    WVT5Q3AO13DESC: "evaluation:wideViewTools.WVT5Q3AO13DESC",
    WVT5Q3AO14: "evaluation:wideViewTools.WVT5Q3AO14",
    WVT5Q3AO15: "evaluation:wideViewTools.WVT5Q3AO15",
    WVT5Q3AO16: "evaluation:wideViewTools.WVT5Q3AO16",
    WVT5Q3AO17: "evaluation:wideViewTools.WVT5Q3AO17",
    WVT5Q3AO18: "evaluation:wideViewTools.WVT5Q3AO18",
    WVT5Q3AO19: "evaluation:wideViewTools.WVT5Q3AO19",
    WVT5Q3AO1DESC: "evaluation:wideViewTools.WVT5Q3AO1DESC",
    WVT5Q3AO2: "evaluation:wideViewTools.WVT5Q3AO2",
    WVT5Q3AO20: "evaluation:wideViewTools.WVT5Q3AO20",
    WVT5Q3AO21: "evaluation:wideViewTools.WVT5Q3AO21",
    WVT5Q3AO21DESC: "evaluation:wideViewTools.WVT5Q3AO21DESC",
    WVT5Q3AO22: "evaluation:wideViewTools.WVT5Q3AO22",
    WVT5Q3AO23: "evaluation:wideViewTools.WVT5Q3AO23",
    WVT5Q3AO3: "evaluation:wideViewTools.WVT5Q3AO3",
    WVT5Q3AO4: "evaluation:wideViewTools.WVT5Q3AO4",
    WVT5Q3AO4DESC: "evaluation:wideViewTools.WVT5Q3AO4DESC",
    WVT5Q3AO5: "evaluation:wideViewTools.WVT5Q3AO5",
    WVT5Q3AO6: "evaluation:wideViewTools.WVT5Q3AO6",
    WVT5Q3AO7: "evaluation:wideViewTools.WVT5Q3AO7",
    WVT5Q3AO7DESC: "evaluation:wideViewTools.WVT5Q3AO7DESC",
    WVT5Q3AO8: "evaluation:wideViewTools.WVT5Q3AO8",
    WVT5Q3AO9: "evaluation:wideViewTools.WVT5Q3AO9",
    WVT5Q3AO9DESC: "evaluation:wideViewTools.WVT5Q3AO9DESC",
    WVT5Q3Title: "evaluation:wideViewTools.WVT5Q3Title",
    WVT5Q4Title: "evaluation:wideViewTools.WVT5Q4Title",
    WVT5Q5Title: "evaluation:wideViewTools.WVT5Q5Title",
    WVT6Q1AO1: "evaluation:wideViewTools.WVT6Q1AO1",
    WVT6Q1AO2: "evaluation:wideViewTools.WVT6Q1AO2",
    WVT6Q1AO3: "evaluation:wideViewTools.WVT6Q1AO3",
    WVT6Q1AO4: "evaluation:wideViewTools.WVT6Q1AO4",
    WVT6Q1AO5: "evaluation:wideViewTools.WVT6Q1AO5",
    WVT6Q1AO6: "evaluation:wideViewTools.WVT6Q1AO6",
    WVT6Q1AO7: "evaluation:wideViewTools.WVT6Q1AO7",
    WVT6Q1AO8: "evaluation:wideViewTools.WVT6Q1AO8",
    WVT6Q1Description: "evaluation:wideViewTools.WVT6Q1Description",
    WVT6Q1Title: "evaluation:wideViewTools.WVT6Q1Title",
    WVT6Q2Title: "evaluation:wideViewTools.WVT6Q2Title",
    WVT7Q1Description: "evaluation:wideViewTools.WVT7Q1Description",
    WVT7Q1Title: "evaluation:wideViewTools.WVT7Q1Title",
    WVT7Q2Title: "evaluation:wideViewTools.WVT7Q2Title",
    WVT7Q3Title: "evaluation:wideViewTools.WVT7Q3Title",
    WVT7Q4Title: "evaluation:wideViewTools.WVT7Q4Title",
    WVT7Q5Title: "evaluation:wideViewTools.WVT7Q5Title",
    WVT7Q6Title: "evaluation:wideViewTools.WVT7Q6Title",
    WVT7Q7AO1: "evaluation:wideViewTools.WVT7Q7AO1",
    WVT7Q7AO2: "evaluation:wideViewTools.WVT7Q7AO2",
    WVT7Q7AO3: "evaluation:wideViewTools.WVT7Q7AO3",
    WVT7Q7Title: "evaluation:wideViewTools.WVT7Q7Title",
    WVT7Q8Title: "evaluation:wideViewTools.WVT7Q8Title",
    WVT8Q1Title: "evaluation:wideViewTools.WVT8Q1Title",
    WVT8Q2Title: "evaluation:wideViewTools.WVT8Q2Title",
    WVT8Q3Title: "evaluation:wideViewTools.WVT8Q3Title",
    WVT8Q4Title: "evaluation:wideViewTools.WVT8Q4Title",
    WVT8Q5Title: "evaluation:wideViewTools.WVT8Q5Title",
    WVT9Description: "evaluation:wideViewTools.WVT9Description",
    WVT9Instruction: "evaluation:wideViewTools.WVT9Instruction",
    WVT9Q10Title: "evaluation:wideViewTools.WVT9Q10Title",
    WVT9Q11Title: "evaluation:wideViewTools.WVT9Q11Title",
    WVT9Q12Title: "evaluation:wideViewTools.WVT9Q12Title",
    WVT9Q13Title: "evaluation:wideViewTools.WVT9Q13Title",
    WVT9Q14HeaderText: "evaluation:wideViewTools.WVT9Q14HeaderText",
    WVT9Q14Title: "evaluation:wideViewTools.WVT9Q14Title",
    WVT9Q15Title: "evaluation:wideViewTools.WVT9Q15Title",
    WVT9Q16Title: "evaluation:wideViewTools.WVT9Q16Title",
    WVT9Q17Title: "evaluation:wideViewTools.WVT9Q17Title",
    WVT9Q18Title: "evaluation:wideViewTools.WVT9Q18Title",
    WVT9Q19Title: "evaluation:wideViewTools.WVT9Q19Title",
    WVT9Q1AO1: "evaluation:wideViewTools.WVT9Q1AO1",
    WVT9Q1AO2: "evaluation:wideViewTools.WVT9Q1AO2",
    WVT9Q1AO3: "evaluation:wideViewTools.WVT9Q1AO3",
    WVT9Q1Title: "evaluation:wideViewTools.WVT9Q1Title",
    WVT9Q20Title: "evaluation:wideViewTools.WVT9Q20Title",
    WVT9Q21Title: "evaluation:wideViewTools.WVT9Q21Title",
    WVT9Q22Title: "evaluation:wideViewTools.WVT9Q22Title",
    WVT9Q23Title: "evaluation:wideViewTools.WVT9Q23Title",
    WVT9Q24Title: "evaluation:wideViewTools.WVT9Q24Title",
    WVT9Q25Title: "evaluation:wideViewTools.WVT9Q25Title",
    WVT9Q26Title: "evaluation:wideViewTools.WVT9Q26Title",
    WVT9Q27Title: "evaluation:wideViewTools.WVT9Q27Title",
    WVT9Q28HeaderText: "evaluation:wideViewTools.WVT9Q28HeaderText",
    WVT9Q28Title: "evaluation:wideViewTools.WVT9Q28Title",
    WVT9Q29Title: "evaluation:wideViewTools.WVT9Q29Title",
    WVT9Q2Description: "evaluation:wideViewTools.WVT9Q2Description",
    WVT9Q2HeaderText: "evaluation:wideViewTools.WVT9Q2HeaderText",
    WVT9Q2Title: "evaluation:wideViewTools.WVT9Q2Title",
    WVT9Q30Title: "evaluation:wideViewTools.WVT9Q30Title",
    WVT9Q31Title: "evaluation:wideViewTools.WVT9Q31Title",
    WVT9Q32Title: "evaluation:wideViewTools.WVT9Q32Title",
    WVT9Q33Title: "evaluation:wideViewTools.WVT9Q33Title",
    WVT9Q34Title: "evaluation:wideViewTools.WVT9Q34Title",
    WVT9Q35Title: "evaluation:wideViewTools.WVT9Q35Title",
    WVT9Q36Title: "evaluation:wideViewTools.WVT9Q36Title",
    WVT9Q37Title: "evaluation:wideViewTools.WVT9Q37Title",
    WVT9Q38Description: "evaluation:wideViewTools.WVT9Q38Description",
    WVT9Q38HeaderText: "evaluation:wideViewTools.WVT9Q38HeaderText",
    WVT9Q38Title: "evaluation:wideViewTools.WVT9Q38Title",
    WVT9Q39Title: "evaluation:wideViewTools.WVT9Q39Title",
    WVT9Q3AO1: "evaluation:wideViewTools.WVT9Q3AO1",
    WVT9Q3AO2: "evaluation:wideViewTools.WVT9Q3AO2",
    WVT9Q3AO3: "evaluation:wideViewTools.WVT9Q3AO3",
    WVT9Q3AO4: "evaluation:wideViewTools.WVT9Q3AO4",
    WVT9Q3AO5: "evaluation:wideViewTools.WVT9Q3AO5",
    WVT9Q3AO6: "evaluation:wideViewTools.WVT9Q3AO6",
    WVT9Q3AO7: "evaluation:wideViewTools.WVT9Q3AO7",
    WVT9Q3AO8: "evaluation:wideViewTools.WVT9Q3AO8",
    WVT9Q3Description: "evaluation:wideViewTools.WVT9Q3Description",
    WVT9Q3Title: "evaluation:wideViewTools.WVT9Q3Title",
    WVT9Q40Title: "evaluation:wideViewTools.WVT9Q40Title",
    WVT9Q41Title: "evaluation:wideViewTools.WVT9Q41Title",
    WVT9Q42Title: "evaluation:wideViewTools.WVT9Q42Title",
    WVT9Q43Title: "evaluation:wideViewTools.WVT9Q43Title",
    WVT9Q44Title: "evaluation:wideViewTools.WVT9Q44Title",
    WVT9Q45Title: "evaluation:wideViewTools.WVT9Q45Title",
    WVT9Q46Description: "evaluation:wideViewTools.WVT9Q46Description",
    WVT9Q46HeaderText: "evaluation:wideViewTools.WVT9Q46HeaderText",
    WVT9Q46Title: "evaluation:wideViewTools.WVT9Q46Title",
    WVT9Q47Title: "evaluation:wideViewTools.WVT9Q47Title",
    WVT9Q48Title: "evaluation:wideViewTools.WVT9Q48Title",
    WVT9Q49Title: "evaluation:wideViewTools.WVT9Q49Title",
    WVT9Q4AO1: "evaluation:wideViewTools.WVT9Q4AO1",
    WVT9Q4AO10: "evaluation:wideViewTools.WVT9Q4AO10",
    WVT9Q4AO11: "evaluation:wideViewTools.WVT9Q4AO11",
    WVT9Q4AO2: "evaluation:wideViewTools.WVT9Q4AO2",
    WVT9Q4AO3: "evaluation:wideViewTools.WVT9Q4AO3",
    WVT9Q4AO4: "evaluation:wideViewTools.WVT9Q4AO4",
    WVT9Q4AO5: "evaluation:wideViewTools.WVT9Q4AO5",
    WVT9Q4AO6: "evaluation:wideViewTools.WVT9Q4AO6",
    WVT9Q4AO7: "evaluation:wideViewTools.WVT9Q4AO7",
    WVT9Q4AO8: "evaluation:wideViewTools.WVT9Q4AO8",
    WVT9Q4AO9: "evaluation:wideViewTools.WVT9Q4AO9",
    WVT9Q4Description: "evaluation:wideViewTools.WVT9Q4Description",
    WVT9Q4Title: "evaluation:wideViewTools.WVT9Q4Title",
    WVT9Q50Title: "evaluation:wideViewTools.WVT9Q50Title",
    WVT9Q51Title: "evaluation:wideViewTools.WVT9Q51Title",
    WVT9Q52Title: "evaluation:wideViewTools.WVT9Q52Title",
    WVT9Q53Title: "evaluation:wideViewTools.WVT9Q53Title",
    WVT9Q54Title: "evaluation:wideViewTools.WVT9Q54Title",
    WVT9Q55Title: "evaluation:wideViewTools.WVT9Q55Title",
    WVT9Q56Title: "evaluation:wideViewTools.WVT9Q56Title",
    WVT9Q57Description: "evaluation:wideViewTools.WVT9Q57Description",
    WVT9Q57HeaderText: "evaluation:wideViewTools.WVT9Q57HeaderText",
    WVT9Q57Title: "evaluation:wideViewTools.WVT9Q57Title",
    WVT9Q58Title: "evaluation:wideViewTools.WVT9Q58Title",
    WVT9Q59Title: "evaluation:wideViewTools.WVT9Q59Title",
    WVT9Q5AO1: "evaluation:wideViewTools.WVT9Q5AO1",
    WVT9Q5AO10: "evaluation:wideViewTools.WVT9Q5AO10",
    WVT9Q5AO11: "evaluation:wideViewTools.WVT9Q5AO11",
    WVT9Q5AO12: "evaluation:wideViewTools.WVT9Q5AO12",
    WVT9Q5AO13: "evaluation:wideViewTools.WVT9Q5AO13",
    WVT9Q5AO14: "evaluation:wideViewTools.WVT9Q5AO14",
    WVT9Q5AO15: "evaluation:wideViewTools.WVT9Q5AO15",
    WVT9Q5AO16: "evaluation:wideViewTools.WVT9Q5AO16",
    WVT9Q5AO2: "evaluation:wideViewTools.WVT9Q5AO2",
    WVT9Q5AO3: "evaluation:wideViewTools.WVT9Q5AO3",
    WVT9Q5AO4: "evaluation:wideViewTools.WVT9Q5AO4",
    WVT9Q5AO5: "evaluation:wideViewTools.WVT9Q5AO5",
    WVT9Q5AO6: "evaluation:wideViewTools.WVT9Q5AO6",
    WVT9Q5AO7: "evaluation:wideViewTools.WVT9Q5AO7",
    WVT9Q5AO8: "evaluation:wideViewTools.WVT9Q5AO8",
    WVT9Q5AO9: "evaluation:wideViewTools.WVT9Q5AO9",
    WVT9Q5Description: "evaluation:wideViewTools.WVT9Q5Description",
    WVT9Q5Title: "evaluation:wideViewTools.WVT9Q5Title",
    WVT9Q60Title: "evaluation:wideViewTools.WVT9Q60Title",
    WVT9Q61Title: "evaluation:wideViewTools.WVT9Q61Title",
    WVT9Q62Title: "evaluation:wideViewTools.WVT9Q62Title",
    WVT9Q63Title: "evaluation:wideViewTools.WVT9Q63Title",
    WVT9Q64Title: "evaluation:wideViewTools.WVT9Q64Title",
    WVT9Q65Title: "evaluation:wideViewTools.WVT9Q65Title",
    WVT9Q66Title: "evaluation:wideViewTools.WVT9Q66Title",
    WVT9Q67Title: "evaluation:wideViewTools.WVT9Q67Title",
    WVT9Q68Title: "evaluation:wideViewTools.WVT9Q68Title",
    WVT9Q69Title: "evaluation:wideViewTools.WVT9Q69Title",
    WVT9Q6Title: "evaluation:wideViewTools.WVT9Q6Title",
    WVT9Q70Title: "evaluation:wideViewTools.WVT9Q70Title",
    WVT9Q71Title: "evaluation:wideViewTools.WVT9Q71Title",
    WVT9Q72Title: "evaluation:wideViewTools.WVT9Q72Title",
    WVT9Q73Title: "evaluation:wideViewTools.WVT9Q73Title",
    WVT9Q7Title: "evaluation:wideViewTools.WVT9Q7Title",
    WVT9Q8Title: "evaluation:wideViewTools.WVT9Q8Title",
    WVT9Q9Title: "evaluation:wideViewTools.WVT9Q9Title",
    WVT9Reason: "evaluation:wideViewTools.WVT9Reason",
    WVT9Title: "evaluation:wideViewTools.WVT9Title",
    WWT1Description: "evaluation:wideViewTools.WWT1Description",
    WWT1Instruction: "evaluation:wideViewTools.WWT1Instruction",
    WWT1Materials: "evaluation:wideViewTools.WWT1Materials",
    WWT1Reason: "evaluation:wideViewTools.WWT1Reason",
    WWT1Title: "evaluation:wideViewTools.WWT1Title",
    WWT2Description: "evaluation:wideViewTools.WWT2Description",
    WWT2Instruction: "evaluation:wideViewTools.WWT2Instruction",
    WWT2Materials: "evaluation:wideViewTools.WWT2Materials",
    WWT2Reason: "evaluation:wideViewTools.WWT2Reason",
    WWT2Title: "evaluation:wideViewTools.WWT2Title",
    WWT3Description: "evaluation:wideViewTools.WWT3Description",
    WWT3Instruction: "evaluation:wideViewTools.WWT3Instruction",
    WWT3Reason: "evaluation:wideViewTools.WWT3Reason",
    WWT3Title: "evaluation:wideViewTools.WWT3Title",
    WWT4Description: "evaluation:wideViewTools.WWT4Description",
    WWT4Instruction: "evaluation:wideViewTools.WWT4Instruction",
    WWT4Materials: "evaluation:wideViewTools.WWT4Materials",
    WWT4Reason: "evaluation:wideViewTools.WWT4Reason",
    WWT4Title: "evaluation:wideViewTools.WWT4Title",
    WWT5Description: "evaluation:wideViewTools.WWT5Description",
    WWT5Instruction: "evaluation:wideViewTools.WWT5Instruction",
    WWT5Materials: "evaluation:wideViewTools.WWT5Materials",
    WWT5Reason: "evaluation:wideViewTools.WWT5Reason",
    WWT5Title: "evaluation:wideViewTools.WWT5Title",
    WWT6Description: "evaluation:wideViewTools.WWT6Description",
    WWT6Instruction: "evaluation:wideViewTools.WWT6Instruction",
    WWT6Materials: "evaluation:wideViewTools.WWT6Materials",
    WWT6Reason: "evaluation:wideViewTools.WWT6Reason",
    WWT6Title: "evaluation:wideViewTools.WWT6Title",
    WWT7Description: "evaluation:wideViewTools.WWT7Description",
    WWT7Instruction: "evaluation:wideViewTools.WWT7Instruction",
    WWT7Materials: "evaluation:wideViewTools.WWT7Materials",
    WWT7Reason: "evaluation:wideViewTools.WWT7Reason",
    WWT7Title: "evaluation:wideViewTools.WWT7Title",
    WWT8Description: "evaluation:wideViewTools.WWT8Description",
    WWT8Instruction: "evaluation:wideViewTools.WWT8Instruction",
    WWT8Reason: "evaluation:wideViewTools.WWT8Reason",
    WWT8Title: "evaluation:wideViewTools.WWT8Title",
    ratingScaleLabel: "evaluation:wideViewTools.ratingScaleLabel",
  },
};

export default translationMapEvaluation;
