import { Box, Link, Typography } from "@infinitaslearning/pixel-design-system";
import CircleUserIcon from "@infinitaslearning/pixel-icons/CircleUser";
import CircleQuestionIcon from "@infinitaslearning/pixel-icons/CircleQuestion";
import ArrowRightFromBracketIcon from "@infinitaslearning/pixel-icons/ArrowRightFromBracket";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { getSignOutUrl } from "@utils/idp-helpers";
import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const linkVariant = "body1Bold";
const ID_TOKEN_COOKIE_NAME = "id_token";
const SIGNED_IN_COOKIE_NAME = "signed_in";

const ICON_SX = { mr: 1 };
const LINK_SX = { cursor: "pointer", display: "flex", alignItems: "center" };

export const AccountLinks = () => {
  const { t } = useTranslation("common", { keyPrefix: "user-sidebar" });
  const router = useRouter();
  const { data: session } = useSession();
  const [_, __, removeCookie] = useCookies([ID_TOKEN_COOKIE_NAME, SIGNED_IN_COOKIE_NAME]);
  const [signOutUrl, setSignOutUrl] = useState<string>("");

  useEffect(() => {
    if (session) {
      getSignOutUrl(window.location.origin, session).then((url) => {
        if (url) {
          setSignOutUrl(url);
        }
      });
    }
  }, [session]);

  const handleSignOut = async () => {
    removeCookie(ID_TOKEN_COOKIE_NAME);
    removeCookie(SIGNED_IN_COOKIE_NAME);
    // we always need to make sure we clear local cookies before redirecting to the idp
    await signOut({ redirect: false });
    return router.push(signOutUrl);
  };

  const trackElementClick = (elementId: string) => {
    getAnalyticsTracker()?.elementClicked({
      elementId,
      type: "link",
      module: "user-sidebar",
      itemId: elementId,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Link href={t("user-details-link")} rel="noreferrer" target="_blank" sx={LINK_SX}>
        <CircleUserIcon sx={ICON_SX} color="primary" />
        <Typography
          onClick={() => {
            trackElementClick("user-details-link");
          }}
          variant={linkVariant}
          component="span"
        >
          {t("user-details")}
        </Typography>
      </Link>

      <Link href={t("help-link")} target="_blank" sx={LINK_SX}>
        <CircleQuestionIcon sx={ICON_SX} color="primary" />
        <Typography
          onClick={() => {
            trackElementClick("help-link");
          }}
          variant={linkVariant}
          component="span"
        >
          {t("help")}
        </Typography>
      </Link>

      <Link onClick={handleSignOut} sx={LINK_SX}>
        <ArrowRightFromBracketIcon sx={ICON_SX} color="primary" />
        <Typography
          onClick={() => {
            trackElementClick("logout-link");
          }}
          variant={linkVariant}
          component="span"
        >
          {t("logout")}
        </Typography>
      </Link>
    </Box>
  );
};
